
export const quoteAssessmentCustomerForm = [
  {
    'Id': 16252,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': '',
    'ItemValue': 'quote_id',
    'Category': null,
    'SortOrder': '1',
    'Value': [],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'quote_id',
    'placeholder': 'Enter Quote Id',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '15',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '30',
    'maxWidth': '100',
    'width': '30',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16287,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'heading_customer',
    'ItemValue': 'Customer Details',
    'Category': null,
    'SortOrder': '2',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'Customer Details',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16357,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'upload_license',
    'ItemValue': 'Upload Driving License',
    'Category': null,
    'SortOrder': '3',
    'Value': [],
    'controlType': 'button',
    'label': 'Button',
    'icon': 'camera',
    'visible': 'true',
    'fieldValue': 'upload_driving_license',
    'prefillControls': '[]',
    'placeholder': 'upload_driving_license',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:31:19 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:31:19 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '50',
    'alignment': 'right',
    'fullWidth': 'false'
  },
  {
    'Id': 16322,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'mandatory_fields',
    'ItemValue': '*mandatory fields',
    'Category': null,
    'SortOrder': '4',
    'Value': [],
    'controlType': 'description',
    'label': 'Paragraph',
    'icon': 'description',
    'visible': 'true',
    'textPrefillValue': '*mandatory fields',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'customer_first_name',
    'ItemValue': 'CUSTOMER FIRST NAME',
    'Category': null,
    'SortOrder': '5',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_first_name',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        'itemType': 'validations',
        'type': 'pattern'
    
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'firstName',
    'placeholder': 'first_name',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16427,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'customer_last_name',
    'ItemValue': 'CUSTOMER LAST NAME',
    'Category': null,
    'SortOrder': '6',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_last_name',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        'itemType': 'validations',
        'type': 'pattern'
    
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'placeholder': 'last_name',
    'fieldValue': 'lastName',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16462,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'email',
    'ItemValue': 'EMAIL',
    'Category': null,
    'SortOrder': '7',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'email_valid',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
        'itemType': 'validations',
        'type': 'pattern'
      }
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'email',
    'placeholder': 'email',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16501,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'code',
    'ItemValue': 'CODE',
    'Category': null,
    'SortOrder': '8',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'code',
    'placeholder': 'code',
    'width': '15',
    'minWidth': '15',
    'maxWidth': '15',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/Countries',
    'optionsKeyValue': 'phoneCountryCode',
    'optionsKeyLabel': 'phoneCountryCode',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16536,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'mobile_number',
    'ItemValue': 'MOBILE NUMBER',
    'Category': null,
    'SortOrder': '9',
    'Value': [ {
      'IsActive': false,
      'ItemKey': 'Pattern',
      'ItemValue': 'valid_mobile_number',
      'Category': null,
      'SortOrder': 0,
      'Value': [],
      'regex': '^[0-9]+[0-9 ,.\'-]+$',
      'itemType': 'validations',
      'type': 'pattern'
    } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'mobileNumber',
    'placeholder': 'mobile_number',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '10',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '35',
    'maxWidth': '35',
    'width': '35',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16571,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'vehicle_detail',
    'ItemValue': 'Vehicle Detail',
    'Category': null,
    'SortOrder': '10',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'vehicle_detail',
    'fieldValue': 'vehicle_detail',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16606,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'upload_registration',
    'ItemValue': 'Upload registration card',
    'Category': null,
    'SortOrder': '11',
    'Value': [],
    'controlType': 'button',
    'label': 'Button',
    'icon': 'camera',
    'visible': 'true',
    'fieldValue': 'upload_registration',
    'placeholder': 'upload_registration',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': 'custom',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'openThis($event)',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'right',
    'fullWidth': 'false'
  },
  {
    'Id': 16648,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'vin',
    'ItemValue': 'VIN',
    'Category': null,
    'SortOrder': '13',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'pattern_vin',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-hA-Hj-nJ-NpPr-zR-Z0-9]+$',
        'itemType': 'validations',
        'type': 'pattern'
      } 
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'fieldValue': 'vin',
    'icon': 'text_fields',
    'prefillControls': '', //'[{"controlName":"makeID","prefillAttribute":"vehicleMake"},{"controlName":"modelID","prefillAttribute":"vehicleModel"}]',
    'placeholder': 'vin',
    'required': 'true',
    'readonly': 'false',
    'visible': 'true',
    'maxLength': '17',
    'blockMaxLength': 'true',
    'prefillMinLength': '17',
    'minDate': 'Fri Sep 27 2024 15:34:13 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:34:13 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '', //'api/v1/Vehicles/vehicleDetailsByVIN/{this}',
    'optionsService': '', //'autoFoundation',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16683,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'make',
    'ItemValue': 'make',
    'Category': null,
    'SortOrder': '14',
    'Value': [
      {
        'Id': 16718,
        'TemplateId': 206,
        'DataId': 112,
        'ConfigurationItemId': 1253,
        'IsActive': true,
        'ItemKey': 'Disabled If',
        'ItemValue': '\'\'',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'type': 'disabledIf',
        'controlKey': 'vin',
        'itemType': 'conditions',
        'operator': '<=',
        'conditionalValue': '\'\''
      }
    ],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'makeID',
    'required': 'true',
    'optionsService': 'autoFoundation',
    'optionsUrl': 'api/v1/VehicleMakes',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'display': '',
    'prefillControls': '[{"controlName":"modelID","prefillAttribute":"name"}]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:41:35 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:41:35 GMT+0545 (Nepal Time)',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'model',
    'ItemValue': 'MODEL',
    'Category': null,
    'SortOrder': '15',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'modelID',
    'required': 'true',
    'optionsService': 'autoFoundation',
    'optionsUrl': 'api/v1/VehicleMakes/VehicleModels/{makeID}/1',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'modelName',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16759,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'mileage',
    'ItemValue': 'MILEAGE',
    'Category': null,
    'SortOrder': '16',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'pattern_numeric',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[0-9]+$',
        'itemType': 'validations',
        'type': 'pattern'
      } 
    ],
    'controlType': 'textbox',
    'label': 'Numeric',
    'icon': '123',
    'visible': 'true',
    'fieldValue': 'mileage',
    'placeholder': 'mileage',
    'required': 'false',
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '10',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16794,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'registration_date',
    'ItemValue': 'REGISTRATION DATE',
    'Category': null,
    'SortOrder': '17',
    'Value': [

    ],
    'controlType': 'date',
    'label': 'Date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'registrationDate',
    'placeholder': 'registrationDate',
    'required': 'true',
    'minDate': new Date('1980-01-01'),
    'maxDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16829,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'manufacturing_date',
    'ItemValue': 'MANUFACTURING DATE',
    'Category': null,
    'SortOrder': '18',
    'Value': [],
    'controlType': 'date',
    'label': 'Date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'manufacturingDate',
    'placeholder': 'manufacturingDate',
    'required': 'false',
    'minDate': new Date('1980-01-01'),
    'maxDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  /*{
    'Id': 16864,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'importInterval',
    'ItemValue': 'IMPORT INTERVAL',
    'Category': null,
    'SortOrder': '19',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'modelYearRange',
    'optionsService': 'autoFoundation',
    'optionsKeyValue': 'name',
    'optionsKeyLabel': 'name',
    'optionsUrl': 'api/v1/VehicleMakes/VehicleModelYears/{modelID}/3',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },*/
  {
    'Id': 16934,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'body_type',
    'ItemValue': 'VEHICLE TYPE',
    'Category': null,
    'SortOrder': '19',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'vehicleType',
    'required': 'false',
    'optionsService': 'autoFoundation',
    'optionsUrl': 'api/v1/VehicleTypes',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16899,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'license_plate',
    'ItemValue': 'LICENSE PLATE',
    'Category': null,
    'SortOrder': '20',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'Only alphanumeric characters are allowed',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9]*$',
        'itemType': 'validations',
        'type': 'pattern'
      }
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'plateNumber',
    'required': 'true',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '12',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16969,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'communicationChannel',
    'ItemValue': 'Preferred Communication Channel',
    'Category': null,
    'SortOrder': '21',
    'Value': [],
    'controlType': 'title',
    'label': 'title',
    'fieldValue': 'communicationChannel',
    'icon': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'visible': 'true',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:12:47 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:12:47 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': 'Preferred Communication Channel *',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '100',
    'maxWidth': '100',
    'width': '100',
    'alignment': '',
    'fullWidth': 'true'
  },
  {
    'Id': 17031,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'multiCheckBox',
    'ItemValue': 'multiCheckBox',
    'Category': null,
    'SortOrder': '22',
    'Value': [],
    'controlType': 'checkbox-group',
    'label': 'Multi-Select Checkbox',
    'icon': 'check_box',
    'visible': 'true',
    'fieldValue': 'multiCheckBox',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'maxDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '',
    'alignment': '',
    'fullWidth': 'false',
    'options': [
      {
        'label': 'Whatsapp',
        'value': 1
      },
      {
        'label': 'Email',
        'value': 2
      },
      {
        'label': 'SMS',
        'value': 3
      }
    ]
  }
]