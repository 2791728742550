export const CASE_STATUS = {
  'Draft': 'Draft',
  'PendingInformation': 'PendingInformation',
  'CustomerSubmitted': 'CustomerSubmitted',
  'InReview': 'InReview',
  'Completed': 'Completed'
}

export const CASE_STATUS_TEXT_DIC = {
  'Draft': 'Draft',
  'PendingInformation': 'Pending Information',
  'CustomerSubmitted': 'Customer Submitted',
  'InReview': 'In Review',
  'Completed': 'Completed'
}

export const DAMAGE_STATUS = {
  'NoDamageDetected': 'No Damage Detected',
  'DamageDetected': 'Damage Detected',
  'FailedAIAnalysis': 'Failed AI Analysis'
}

export const DAMAGE_STATUS_TEXT_DIC = {
  'NoDamageDetected': 'No Damage Detected',
  'DamageDetected': 'Damage Detected',
  'FailedAIAnalysis': 'Failed AI Analysis'
}

export const IN_PROGRESS_CASES = [ CASE_STATUS.PendingInformation, CASE_STATUS.CustomerSubmitted, CASE_STATUS.InReview ]