<div class="operation-container fx-col">
    <div class="header">
        <h3>{{'operation' | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <div class="content-header" *ngIf="!editrepairEstimateOperation">
            <chq-tabs [mode]="'button'" [menuItems]="menuItems" (menuItemClick)="onMenuItemClick($event);"></chq-tabs>
        </div>
        <div class="content-body fx-1">
            <ng-container *ngIf="activeMenu === 'add-parts'">
                <app-part-add></app-part-add>
            </ng-container>
            <ng-container *ngIf="activeMenu === 'create-parts-operations'">
                <app-operation-add [operationToEdit]="operationToEdit" (cancelDialog)="cancelDialog($event)" (switchToOperationLIst)="switchToOperationList()" [repairId]="data.repairId" [estimationMode]="data.estimationMode" [addedParts]="data.addedParts"></app-operation-add>
            </ng-container>
            <ng-container *ngIf="activeMenu === 'operation-list'">
                <app-operation-list (handleEditRow)="switchToEditOperation($event);" (cancelDialog)="cancelDialog($event)" [repairId]="data.repairId"></app-operation-list>
            </ng-container>
        </div>
    </div>    
</div>