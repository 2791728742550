import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { EstimateNotesComponent } from 'src/app/dialogs/estimate-notes/estimate-notes.component';
import { CommentDialogComponent } from 'src/app/dialogs/comment-dialog/comment-dialog.component';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { manualEstimateCodeInput, newEstimateOperationForm, newLabourTimeWidget, newPriceWidget, newQuantityWidget } from 'src/app/helper/form/estimate-operation.helper';
import { TranslateService } from '@ngx-translate/core';
import { IControlValue } from 'src/app/model/chq-widgets-input-model';
import { ManualEstimateCalculationType, UpsaleEditNotAllowedStatuses } from 'src/app/config/constants/app.constants';
@Component({
  selector: 'chq-estimate-details',
  templateUrl: './chq-estimate-details.component.html',
  styleUrls: [ './chq-estimate-details.component.scss' ]
})

export class ChqEstimateDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() repairGuid?: any;
  @Input() estimateGuid?: any; // estimateGuid we only required to update a estimate
  @Input() caseGuid?: any;
  @Input() estimateId?: any;
  @Input() estimateResponse: any;
  @Input() editEstimate: boolean;
  @Input() estimateSource: string | null;
  @Input() serviceType: string;
  @Input() i18n: Localization;
  @Input() selected: string;
  @Input() repairStatus = '';
  @Input() approvalStatus = '';
  @Input() isAiGeneratedEstimate: boolean;
  @Input() damageRecommendations: any;
  @Input() isDamageRecommendationsLoading: boolean;
  @Input() isGroupEdit: boolean;
  @Input() isHideHeaders: boolean;
  @Output() iconClicked: EventEmitter<any> = new EventEmitter();
  @Output() isFormValid: EventEmitter<any> = new EventEmitter();
  @Output() downloadPdf: EventEmitter<any> = new EventEmitter();
  @Output() showFooterBtn: EventEmitter<boolean> = new EventEmitter();
  selectedPdf: number;
  formGroup: FormGroup;
  estimateLog = [];

  showEstimateLog = false;
  fromRepairerView = false;
  estimateHasRejection = {
    partsCost: false,
    labourCost: false,
    materialCost: false
  };

  @Output() radioButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() addEstimateClicked: EventEmitter<any> = new EventEmitter();
  @Output() downloadCSV: EventEmitter<boolean> = new EventEmitter();
  @Input() dropdownOptions: any;

  public dropdownModelFilterConfig: XADropdownConfig = {
    placeHolder: 'Select Report',
    label: '',
    name: 'filterDropdown',
    showHeaderIcon: false
  };

  public repairEstimateReportModel: ChqWidgetsButtonModel = {
    label: 'generate_report',
    type: 'primary',
    icon: 'report-check'
  };

  public estimateLogModel: ChqWidgetsButtonModel = {
    label: 'Estimate Log',
    type: 'outline',
    icon: 'viewFile',
    onclick: this.getEstimateLog.bind(this)
  };

  public repairEstimateReportDownloadCSV: ChqWidgetsButtonModel = {
    label: 'generate_report_csv',
    type: 'secondary',
    icon: 'download-arrow'
  };

  public paintCost: any = [];
  public partsCost: any = [];
  public totalPartsCost: number = 0;
  public labourCost: any = [];
  public totalLabourCost: number = 0;
  public paintLabour: any = [];
  public paintMaterial: any = [];
  public totalPaintCost: number = 0;
  public noDataFound: boolean = false;
  public selectedReport = '';
  public hasEstimateLogPermission: boolean;
  public isMGEstimate: boolean = false;
  public isExternal: boolean = false;
  public calculationType: number;
  public isUpSalesSelected: boolean = false;
  // Summary
  public summary: any = {
    'parts': {
      partsSubTotal: 0,
      sundryParts: 0,
      sundryPartsPercentage: 0,
      partsTotalBeforeDiscount: 0,
      partsTotalDiscountPercentage: 0,
      partsTotal: 0
    },
    'labour': {
      labourTimeHours: 0,
      labourTotalDiscountPercentage: 0,
      labourTotalDiscount: 0,
      labourTotal: 0,
    },
    'paint': {
      'labour': {
        paintLabourTimeHour: 0,
        paintLabourSubTotal: 0,
        paintLabourDiscountPercentage: 0,
        paintLabourDiscount: 0,
        paintLabourTotal: 0,
      },
      'material': {
        paintMaterialSubTotal: 0,
        paintMateriaDiscountPercentage: 0,
        paintMaterialDiscount: 0,
        paintMaterialTotal: 0,
      },
      'paintTotal': 0
    }
  }

  public total: any = {
    total: 0,
    vatPercent: 0,
    vat: 0,
    totalWithVat: 0,
  }

  //Used to highlight rows related to active damage analysis image
  activePartIds = [];
  public userIsBMOrSA = false;
  isUpsaleEditAllowedStatuses : boolean = false;

  /**
    Constructor for the component.
    @param {MonitorService} monitorService - The MonitorService instance.
  */
  constructor(
    private monitorService: MonitorService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private repairEstimateService: RepairEstimateService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,) {

    if (this.router.url.indexOf('/repairer-view/') != -1) {
      this.fromRepairerView = true;
    }

    this.commonService.userProfileData.subscribe((res) => {
      if (res && res.data) {
        const { userPermission } = res.data;
        const integrationWorkFlowDetails = userPermission?.orgPackages?.find(item => item.automotiveServiceName.toLowerCase() === 'integration workflow');
        this.isMGEstimate = this.commonService.hasPermission('mg.estimate', integrationWorkFlowDetails?.permissions);

        const permissionListForTata = userPermission?.automotiveServices.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
        this.isExternal = this.commonService.hasPermission('case.external', permissionListForTata);

        const serviceName = this.router.url.includes('/quote') ? 'carheal quote' : 'addenda repair';
        const service = userPermission?.automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === serviceName);
        const permissionList: any = service?.permissions;
        if (service && permissionList) {
          this.hasEstimateLogPermission = permissionList.map(item => item.permissionName).includes('estimate.view');
        }
        const roles = res?.data?.roleDetail;
        const isBodyShopOrSA = roles?.filter((service) => {
          return service.name.toLowerCase() === 'bodyshop manager' || service.name.toLowerCase() === 'service advisor';
        }).length > 0;

        if (isBodyShopOrSA) {
          this.userIsBMOrSA = true;
        }
      }
    });
  }

  /**
    Lifecycle hook that is called after the component is initialized.
    It sets the data for the component and logs an event using the monitor service.
  */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqEstimateDetailsComponent', 'addenda-repair' ]);
    this.setData();
    this.formGroup = this.fb.group([], { updateOn: 'change' });
    this.formGroup.addControl(
      'reportType',
      new FormControl('')
    );
    this.formGroup.get('reportType')?.statusChanges.subscribe(() => {

      const inputObj = this.formGroup.get('reportType');
      this.selectedReport = inputObj.value;
    });
  }

  /**
    ngOnDestroy
  */
  ngOnDestroy(): void {
    this.commonService.showHistory.next(true)
  }

  /**
    Lifecycle hook that is called when any data-bound property of a directive changes.
    @param {SimpleChanges} changes - An object containing the changed properties and their current and previous values.
  */
  ngOnChanges(changes: SimpleChanges): void {
    this.monitorService.logEvent('ngOnChanges', [ 'ChqEstimateDetailsComponent', 'addenda-repair' ]);
    if (changes) {
      if (changes['estimateResponse'] && changes['estimateResponse'].currentValue) {
        this.estimateResponse = { ...changes['estimateResponse'].currentValue };
        this.setData();
      }
    }
    if (changes) {
      if (changes['damageRecommendations'] && changes['damageRecommendations'].currentValue) {
        this.setDefaultActiveInspectionId();
      }
    }
    if (changes) {
      if (changes['repairStatus'] && changes['repairStatus'].currentValue) {
        this.isUpsaleEditAllowedStatuses = !UpsaleEditNotAllowedStatuses.map(item => item).includes(changes['repairStatus'].currentValue.toLowerCase());
      }
    }
  }
  /**
    hide EstimateLog
  */
  hideEstimateLog(): void {
    this.showEstimateLog = false;
    this.showFooterBtn.emit(true);
    this.commonService.showHistory.next(true)
  }

  /**
   * Description placeholder
   *
   * @returns {*}
   */
  codeValidation():any {
    return {
      name: 'code',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ Validators.maxLength(20), Validators.pattern(/^[a-z\d\s-_\\/]+$/i) ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['maxlength']) {
          return 'code_should_have_not_more_than_20_characters'
        }
        if (error['pattern']) {
          return 'part_operation_only_alphanumberic_characters'
        }
        return '';
      }
    }
  }

  /**
    Sets all the variables for the estimate response data.
    @returns {void}
  */
  setData(): void {
    // Set all the variables
    const estimateCharges = this.estimateResponse?.estimateCharges || [];
    if (estimateCharges.length === 0) this.noDataFound = true
    else {
      this.noDataFound = false
      this.labourCost = estimateCharges?.filter(q => q.serviceType?.toLowerCase() == 'labour');
      this.paintCost = estimateCharges?.filter(q => q.serviceType?.toLowerCase() == 'paint');
      this.partsCost = estimateCharges?.filter(q => q.serviceType?.toLowerCase() == 'part');

      this.estimateHasRejection['partsCost'] = this.partsCost.some(row => row.status === 'Rejected')
      this.estimateHasRejection['paintCost'] = this.paintCost.some(row => row.status === 'Rejected')
      this.estimateHasRejection['labourCost'] = this.labourCost.some(row => row.status === 'Rejected')

      this.paintLabour = this.paintCost?.filter(q => q.serviceSubType?.toLowerCase() == 'paintlabour');
      this.paintMaterial = this.paintCost?.filter(q => q.serviceSubType?.toLowerCase() == 'paintmaterial');


      const summary = this.estimateResponse?.estimateSummary;
      this.totalPartsCost = summary?.partsSubTotal ? parseFloat(summary?.partsSubTotal) : 0
      this.totalLabourCost = summary?.labourTotalBeforeDiscount ? parseFloat(summary?.labourTotalBeforeDiscount) : 0;
      this.totalPaintCost = summary?.paintSubTotal ? parseFloat(summary?.paintSubTotal) : 0;

      this.total.total = this.estimateResponse?.subTotal || 0;
      this.total.vatPercent = this.estimateResponse?.taxPercentage || 0;
      this.total.vat = this.estimateResponse?.taxTotal || 0;
      this.total.totalWithVat = this.estimateResponse?.total || 0;
      if (this.estimateResponse?.currencyCode) {
        this.commonService.setCurrencySymbolFromSymbolMapper(this.estimateResponse?.currencyCode);
        this.i18n = this.commonService.geti18nInfo();
      }

      // Calculate Summary
      this.summary.parts.partsSubTotal = this.estimateResponse?.estimateSummary?.partsSubTotal || 0
      this.summary.parts.sundryParts = this.estimateResponse?.estimateSummary?.sundryParts || 0
      this.summary.parts.sundryPartsPercentage = this.estimateResponse?.estimateSummary?.sundryPartsPercentage || 0
      this.summary.parts.partsTotalBeforeDiscount = this.estimateResponse?.estimateSummary?.partsTotalBeforeDiscount || 0
      this.summary.parts.partsTotalDiscountPercentage = this.estimateResponse?.estimateSummary?.partsTotalDiscountPercentage || 0
      this.summary.parts.partsTotal = this.estimateResponse?.estimateSummary?.partsTotal || 0
      this.summary.parts.partsTotalDiscount = this.estimateResponse?.estimateSummary?.partsTotalDiscount || 0

      this.summary.labour.labourTimeHours = this.estimateResponse?.estimateSummary?.labourTimeHours || 0
      this.summary.labour.labourTotalDiscountPercentage = this.estimateResponse?.estimateSummary?.labourTotalDiscountPercentage || 0
      this.summary.labour.labourTotalDiscount = this.estimateResponse?.estimateSummary?.labourTotalDiscount || 0
      this.summary.labour.labourTotal = this.estimateResponse?.estimateSummary?.labourTotal || 0

      this.summary.paint.labour.paintLabourTimeHour = this.estimateResponse?.estimateSummary?.paintLabourTimeHour || 0
      this.summary.paint.labour.paintLabourSubTotal = this.estimateResponse?.estimateSummary?.paintLabourSubTotal || 0
      this.summary.paint.labour.paintLabourDiscountPercentage = this.estimateResponse?.estimateSummary?.paintLabourDiscountPercentage || 0
      this.summary.paint.labour.paintLabourDiscount = this.estimateResponse?.estimateSummary?.paintLabourDiscount || 0
      this.summary.paint.labour.paintLabourTotal = this.estimateResponse?.estimateSummary?.paintLabourTotal || 0
      this.summary.paint.material.paintMaterialSubTotal = this.estimateResponse?.estimateSummary?.paintMaterialSubTotal || 0
      this.summary.paint.material.paintMateriaDiscountPercentage = this.estimateResponse?.estimateSummary?.paintMateriaDiscountPercentage || 0
      this.summary.paint.material.paintMaterialDiscount = this.estimateResponse?.estimateSummary?.paintMaterialDiscount || 0
      this.summary.paint.material.paintMaterialTotal = this.estimateResponse?.estimateSummary?.paintMaterialTotal || 0
      this.summary.paint.paintTotal = this.estimateResponse?.estimateSummary?.paintTotal || 0

      //Set editable form model
      const calculationType = this.estimateResponse?.calculationTypeId || ManualEstimateCalculationType.EstimatedHours;
      this.calculationType = calculationType;
      this.partsCost.forEach((ele) => {
        ele.isEdit = false;
        ele.formModel = {
          code: { ...manualEstimateCodeInput(), value: ele.code || '', label: '', name: 'code' },
          quantity: { ...newQuantityWidget(), placeHolder: 'Units', name: 'quantity', label: '', value: ele.quantity || 0 },
          cost: { ...newPriceWidget(this.translateService, this.i18n.currencySymbol), value: ele.cost || 0, label: '', name: 'cost' }
        }
        ele.isFormValid = this.validatePart(ele.formModel);

        if (this.isGroupEdit) {
          ele.isEdit = true;
          ele.formModel.code = { ...ele.formModel.code, required: false, validation: this.codeValidation() }
        }
      });

      this.paintMaterial.forEach((ele) => {
        ele.isEdit = false;
        ele.formModel = {
          code: { ...manualEstimateCodeInput(), value: ele.code || '', label: '', name: 'code' },
          quantity: { ...newQuantityWidget(), placeHolder: 'Units', name: 'quantity', label: '', value: ele.quantity || 0 },
          cost: { ...newPriceWidget(this.translateService, this.i18n.currencySymbol), value: ele.cost || 0, label: '', name: 'cost' }
        }
        ele.isFormValid = this.validatePart(ele.formModel);
        if (this.isGroupEdit) {
          ele.isEdit = true;
          ele.formModel.code = { ...ele.formModel.code, required: false, validation: this.codeValidation() }
        }
      })
      // Disable validations
      // In case of Cost per Panel, the Unit/Hours field should not be editable for users for Labour and Paint labour. Cost can be editable.
      // In case of Estimated Hours, the Cost field should not be editable for users for Labour and Paint labour. Unit/Hours can be editable.
      this.labourCost.forEach((ele) => {
        ele.isEdit = false;
        ele.formModel = {
          code: { ...manualEstimateCodeInput(), value: ele.code || '', label: '', name: 'code' },
          quantity: { ...newLabourTimeWidget(), placeHolder: 'Hours', name: 'quantity', label: '', value: ele.quantity || 0, disabled: calculationType === ManualEstimateCalculationType.CostPerPanel }, // might need to change validations
          cost: { ...newPriceWidget(this.translateService, this.i18n.currencySymbol), value: ele.cost || 0, label: '', name: 'cost', disabled: calculationType === ManualEstimateCalculationType.EstimatedHours } // might need to change validations
        }
        ele.isFormValid = this.validatePart(ele.formModel);
        if (this.isGroupEdit) {
          ele.isEdit = true;
          ele.formModel.code = { ...ele.formModel.code, required: false, validation: this.codeValidation() }
        }
      })

      this.paintLabour.forEach((ele) => {
        ele.isEdit = false;
        ele.formModel = {
          code: { ...manualEstimateCodeInput(), value: ele.code || '', label: '', name: 'code' },
          quantity: { ...newLabourTimeWidget(), placeHolder: 'Hours', name: 'quantity', label: '', value: ele.quantity || 0, disabled: calculationType === ManualEstimateCalculationType.CostPerPanel }, // might need to change validations
          cost: { ...newPriceWidget(this.translateService, this.i18n.currencySymbol), value: ele.cost || 0, label: '', name: 'cost', disabled: calculationType === ManualEstimateCalculationType.EstimatedHours } // might need to change validations
        }
        ele.isFormValid = this.validatePart(ele.formModel);
        if (this.isGroupEdit) {
          ele.isEdit = true;
          ele.formModel.code = { ...ele.formModel.code, required: false, validation: this.codeValidation() }
        }
      })

      //Emit event to ManualEstimatePreviewComponent so save button can be disabled if form is invalid
      const invalidEntries = this.partsCost.filter(ele => !ele.isFormValid)
      const paintMaterialInvalidEntries = this.paintMaterial.filter(ele => !ele.isFormValid)
      const labourInvalidEntries = this.labourCost.filter(ele => !ele.isFormValid)
      const paintLabourInvalidEntries = this.paintLabour.filter(ele => !ele.isFormValid)
      this.isFormValid.emit({
        isFormValid: invalidEntries.length === 0 && paintMaterialInvalidEntries.length === 0 && labourInvalidEntries.length === 0 && paintLabourInvalidEntries.length === 0
      })
    }
  }
  /**
    Sets all the variables for the estimate response data.
    @returns {void}
  */
  setDefaultActiveInspectionId(): void {
    // Default active inspection item should first image
    if(this?.damageRecommendations?.damageDetails?.length > 0) {
      this.activePartIds = this.damageRecommendations.damageDetails[0]?.damageDetails?.map(ele => ele.partId)
    }
  }

  /**
    Handles the click event on an icon.
    @param {string} action - The action to perform.
    @param {string} repairOperationGuid - The GUID of the repair operation associated with the icon.
    @fires iconClicked
  */
  handleIconClick(action, estimateChargeId: string): void {
    this.iconClicked.emit({ action, id: estimateChargeId })
  }

  /**
      Handles the click event on an icon.
      @param {string} section - The section to upd.
      @param {string} repairOperationGuid - The GUID of the repair operation associated with the icon.
      @fires iconClicked
    */
  handleEditIconClick(section, index): void {
    this[section][index].isEdit = true
  }

  /**
      Handles the click event on an icon.
      @param {string} section - The section to upd.
      @param {string} repairOperationGuid - The GUID of the repair operation associated with the icon.
      @fires iconClicked
  */
  handleCancelIconClick(section, index, type): void {
    if (type === 'part' || type === 'paintMaterial'|| type === 'labourCost' || type === 'paintLabour') {
      this[section][index].formModel.code = { ...this[section][index].formModel.code, value: this[section][index].code || '' }
      this[section][index].formModel.quantity = { ...this[section][index].formModel.quantity, value: this[section][index].quantity || 0 }
      this[section][index].formModel.cost = { ...this[section][index].formModel.cost, value: this[section][index].cost || 0 }
      this[section][index].isEdit = false;
      this[section][index].totalCalculated = undefined
    }
    this.recalculateValues()
  }

  /**
      Handles the click event on an icon.
      @param {string} section - The section to upd.
      @param {string} repairOperationGuid - The GUID of the repair operation associated with the icon.
      @fires iconClicked
  */
  handleSaveIconClick(section, index, type): void {
    const payloadToUpdate: any = {};
    if (type === 'part' || type === 'paintMaterial' || type === 'labourCost' || type === 'paintLabour') {
      if (!this[section][index].isFormValid) {
        // this.commonService.showToast(0, 'Form validation failed.')
        Object.keys(this[section][index].formModel).forEach((key: string) => {
          if (this[section][index].formModel[key]?.formControl?.markAsTouched) {
            this[section][index].formModel[key].formControl.markAsTouched()
          }
        })
        return
      }
      payloadToUpdate.code = this[section][index].formModel.code.value;
      payloadToUpdate.quantity = this[section][index].formModel.quantity.value ? Number(this[section][index].formModel.quantity.value) : 0
      payloadToUpdate.cost = this[section][index].formModel.cost.value ? Number(this[section][index].formModel.cost.value) : 0
      payloadToUpdate.estimateChargeId = this[section][index].id
    }
    this.commonService.showLoading();
    this.repairEstimateService.updateEstimateV2(this.repairGuid, {
      estimateGuid: this.estimateGuid,
      estimateCharges: [ payloadToUpdate ]
    }).subscribe({
      next: (data) => {
        const estimateCharge = data.data.estimateCharges.filter(item => item.id === payloadToUpdate.estimateChargeId)[0];
        this.commonService.hideLoading();
        // this.iconClicked.emit({ action: 'inPlace-update' })
        this[section][index].isEdit = false;
        this[section][index].isEdited = estimateCharge.isEdited;
        this[section][index].code = payloadToUpdate.code;
        this[section][index].quantity = payloadToUpdate.quantity;
        this[section][index].cost = payloadToUpdate.cost;
      }
    })
  }

  /**
    Updates the form model and form data based on the output received.
    @param {IControlValue} output - The output received from the form control.
    @returns {void}
  */
  onFormUpdate(output: IControlValue, section: string, index): void {
    this[section][index].formModel[output.name].value = output.value;
    this[section][index].isFormValid = this.validatePart(this[section][index].formModel);

    if ((section === 'partsCost' || section === 'paintMaterial') && (output.name === 'quantity' || output.name === 'cost')) {
      this[section][index].totalCalculated = Number(this[section][index].formModel.quantity.value || 0) * Number(this[section][index].formModel.cost.value || 0);
      // recalculate total based on new values entered
      this.recalculateValues()
    } else if ((section === 'labourCost' || section === 'paintLabour') && (output.name === 'quantity' || output.name === 'cost')) {
      let quantity = this[section][index].formModel.quantity.value || 0;
      if(this.calculationType === ManualEstimateCalculationType.CostPerPanel && quantity == 0) quantity = 1
      this[section][index].totalCalculated = Number(quantity) * Number(this[section][index].formModel.cost.value || 0);
      // recalculate total based on new values entered
      this.recalculateValues()
    }
    //Emit event to ManualEstimatePreviewComponent so save button can be disabled if form is invalid
    const invalidEntries = this.partsCost.filter(ele => !ele.isFormValid)
    const paintMaterialInvalidEntries = this.paintMaterial.filter(ele => !ele.isFormValid)
    const labourInvalidEntries = this.labourCost.filter(ele => !ele.isFormValid)
    const paintLabourInvalidEntries = this.paintLabour.filter(ele => !ele.isFormValid)
    this.isFormValid.emit({
      isFormValid: invalidEntries.length === 0 && paintMaterialInvalidEntries.length === 0 && labourInvalidEntries.length === 0 && paintLabourInvalidEntries.length === 0
    })
  }

  /**
    recalculateVales
    @param {string} blobUrl
  */
  recalculateValues(): void {
    // recalculation for parts
    let partTotal = 0
    this.partsCost.forEach((ele) => {
      if(ele.totalCalculated !== undefined) partTotal += ele.totalCalculated;
      else partTotal += Number(ele.total)
    })
    this.totalPartsCost = partTotal;
    if(this.summary.parts) {
      this.summary.parts.partsSubTotal = partTotal;
      this.summary.parts.partsTotalBeforeDiscount = partTotal;
      if(this.summary.parts.partsTotalDiscountPercentage) {
        let discount = (partTotal * this.summary.parts.partsTotalDiscountPercentage)/100;
        discount = Number(discount.toFixed(2))
        this.summary.parts.partsTotalDiscount = discount;
      }
      this.summary.parts.partsTotal = partTotal - this.summary.parts.partsTotalDiscount
    }

    // recalculate paint material cost and paint labour cost
    let paintMaterialCost = 0;
    this.paintMaterial.forEach((ele) => {
      if(ele.totalCalculated !== undefined) paintMaterialCost += ele.totalCalculated;
      else paintMaterialCost += Number(ele.total)
    });

    let paintMaterialLabour = 0;
    let paintMaterialLabourHours = 0;
    this.paintLabour.forEach((ele) => {
      if(ele.totalCalculated !== undefined) paintMaterialLabour += ele.totalCalculated;
      else paintMaterialLabour += Number(ele.total)
      paintMaterialLabourHours += ele.formModel?.quantity?.value ? Number(ele.formModel?.quantity?.value) : Number(ele.quantity)
    });

    this.totalPaintCost = paintMaterialLabour + paintMaterialCost;
    if(this.summary.paint?.material) {
      this.summary.paint.material.paintMaterialSubTotal = paintMaterialCost;
      if(this.summary.paint?.material?.paintMateriaDiscountPercentage) {
        let discount = (paintMaterialCost * this.summary.paint.material.paintMateriaDiscountPercentage)/100;
        discount = Number(discount.toFixed(2))
        this.summary.paint.material.paintMaterialDiscount = discount;
      }
      this.summary.paint.material.paintMaterialTotal = paintMaterialCost - this.summary.paint.material.paintMaterialDiscount;
    }
    if(this.summary.paint?.labour) {
      this.summary.paint.labour.paintLabourSubTotal = paintMaterialLabour;
      if(this.summary.paint?.labour?.paintLabourDiscountPercentage) {
        let discount = (paintMaterialLabour * this.summary.paint.labour.paintLabourDiscountPercentage)/100;
        discount = Number(discount.toFixed(2))
        this.summary.paint.labour.paintLabourDiscount = discount;
      }
      this.summary.paint.labour.paintLabourTotal = paintMaterialLabour - this.summary.paint.labour.paintLabourDiscount;
      this.summary.paint.labour.paintLabourTimeHour = paintMaterialLabourHours
    }
    this.summary.paint.paintTotal = Number(this.summary.paint.material.paintMaterialTotal) + Number(this.summary?.paint?.labour?.paintLabourTotal)

    //recalculate labour cost
    let labourCost = 0;
    let totalLabourHours = 0;
    this.labourCost.forEach((ele) => {
      if(ele.totalCalculated !== undefined) labourCost += ele.totalCalculated;
      else labourCost += Number(ele.total)
      totalLabourHours += ele.formModel?.quantity?.value ? Number(ele.formModel?.quantity?.value) : Number(ele.quantity)
    });
    this.totalLabourCost = labourCost;
    if(this.summary.labour) {
      this.summary.labour.labourTimeHours = Number(totalLabourHours.toFixed(1));
      if(this.summary.labour.labourTotalDiscountPercentage) {
        let discount = (labourCost * this.summary.labour.labourTotalDiscountPercentage)/100;
        discount = Number(discount.toFixed(2))
        this.summary.labour.labourTotalDiscount = discount;
      }
      this.summary.labour.labourTotal = labourCost - this.summary.labour.labourTotalDiscount
    }

    let overallCost = 0;
    if(this.summary?.parts?.partsTotal) overallCost += Number(this.summary.parts.partsTotal)
    if(this.summary?.labour?.labourTotal) overallCost += Number(this.summary.labour.labourTotal)
    if(this.summary?.paint?.paintTotal) overallCost += Number(this.summary.paint.paintTotal)
    this.total.total = overallCost
  }

  /**
    validatePart
    @param {string} blobUrl
  */
  validatePart(model): boolean {
    if (model?.code?.formControl?.valid && model?.quantity?.formControl?.valid && model?.cost?.formControl?.valid) {
      return true
    }
    return false
  }

  /**
    view estimate version
    @param {string} blobUrl
  */
  viewEstimate(blobUrl: string): void {
    window.open(blobUrl, '_blank')
  }

  /**
    open Notes Dialog
    @param {string} notes
  */
  openNotesDialog(notes: string): void {
    this.dialog.open(EstimateNotesComponent, {
      width: '479px',
      maxHeight: '479px',
      data: {
        notes: notes
      }
    })
  }

  /**
   * This function is used to display notes based on the properties of the input row object.
   * It checks for various properties in the row object and returns a corresponding string.
   * @returns {string} - Returns a string based on the properties of the row object. If none of the properties are true, it returns an empty string.
   */
  displayNotes(row: any): string {
    if (row['isAztPartDocumentedByGT']) {
      return '<img src="assets/img/gt.png" />'
    } else if (row['isConditionedBy']) {
      return '<b>C </b>'
    } else if (row['isIncluded']) {
      return '<b> I </b>'
    } else if (row['isNotIncluded']) {
      return '<b>NI</b>'
    } else if (row['isOverlappedBy']) {
      return '<b>-</b>'
    } else if (row['isPrepainted']) {
      return '<img src="assets/img/prepainted_part.png" />'
    } else if (row['isThrownBy']) {
      return '<b>+</b>'
    } else if (row['isApproximate']) {
      return '<img src="assets/img/approximate.png" />'
    } else if (row['isManual']) {
      return '<img src="assets/img/white_flag.png" />'
    }

    return '';
  }

  /**
    Returns a boolean value indicating whether the estimate source is 'GTmotive'.
    @returns {string} A boolean value indicating whether the estimate source is 'GTmotive'.
  */
  isGTEstimate(): boolean {
    if (this.estimateSource === 'GTMotive') {
      return true
    }
    return false;
  }

  /**
    Download the repair report.
    @returns {void}
  */
  handledownloadPdf(): void {
    this.downloadPdf.emit(this.selectedReport);
  }

  /**
    Download the repair report in csv format.
    @returns {void}
  */
  handledownloadCSV(): void {
    this.downloadCSV.emit();
  }

  /**
   * Description placeholder
   * @date 12/29/2023 - 7:30:45 PM
   */
  getEstimateLog(): void {
    this.commonService.showLoading();
    this.repairEstimateService.getEstimateLog(this.serviceType, this.estimateId).subscribe({
      next: (data) => {
        this.commonService.hideLoading();
        this.estimateLog = data.data;
        this.showEstimateLog = !this.showEstimateLog;
        this.showFooterBtn.emit(false)
        this.commonService.showHistory.next(false)
      }
    })
  }

  /**
   * formatDate
   * @param {*} dateString
   * @returns {string}
   */
  formatDate(dateString): string {
    return dayjs(dateString).format('DD/MM/YYYY - hh:mm A') //15/09/2023 - 11:30 AM
  }

  /**
   * handleRadioButtonClick
   *
   */
  handleRadioButtonClick(row: any, $event: any): void {
    this.radioButtonClicked.emit({ row, $event })
  }

  /**
   * openCommentDailog
   *
   */
  openCommentDialog(row: any): void {
    this.dialog.open(CommentDialogComponent, {
      data: {
        notes: row.notes,
        hideUpdate: true
      },
      panelClass: 'comment-modalbox',
      autoFocus: false
    })
  }
  /**
   * openCommentDailog
   *
   */
  handleDamageAnalysisImageChange(event): void {
    this.activePartIds = event.partIds
  }
  /**
     * onCheckBoxChange
     *
     */
  onCheckBoxChange(section, index, isChecked): void {
    console.log('Evemt', section, index, isChecked);
    const payload = [ {
      'op': 'replace',
      'path': '/estimateCharges/-',
      'value': { 'id': this[section]?.[index]?.id, 'isUpsaleEnabled': isChecked }
    } ]
    this.commonService.showLoading();
    this.repairEstimateService.markChargeUpSaleNotUpSale(this.repairGuid, payload).subscribe({
      next: (response) => {
        console.log('response', response)
        this.commonService.hideLoading();
      },
      error: (err) => {
        console.log(err);
        this.commonService.hideLoading();
      }
    });
  }

}
