import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as dayjs from 'dayjs';
import { forkJoin, map, pairwise, startWith } from 'rxjs';
import { CASE_STATUS } from 'src/app/config/constants/quote.assessment.constants';
import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { DynamicFormService } from 'src/app/config/core/dynamic-form/dynamic-form.service';
import { quoteAssessmentCustomerForm } from 'src/app/helper/form/quote-assessment-customer.helper';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { NewDocumentsComponent } from 'src/app/shared/new-documents/new-documents.component';
import { NewPhotosComponent } from 'src/app/shared/new-photos/new-photos.component';
import { VideoUploaderComponent } from 'src/app/shared/video-uploader/video-uploader.component';

@Component({
  selector: 'submit-missing-info',
  templateUrl: './submit-missing-info.component.html',
  styleUrls: [ './submit-missing-info.component.scss' ],
  providers: [ DynamicFormService ]
})
export class SubmitMissingInfoComponent implements OnInit {
  @ViewChild('newPhoto') newPhoto: NewPhotosComponent;
  public controls: DynamicFormBase<any>[] | null;
  public vehicleCtrlList: DynamicFormBase<any>[] | null;
  public inspectionId: number;
  public objectId: number;
  public GUID: string = '';
  public vehicleRegistrationOcrTagMap: any = {};
  private drivingOcrTagsMap: any = {};
  public countryId: number;
  public documentCollectionId: number;
  public showDocument: boolean = false;
  public captureVideo: boolean = false;
  public documentFilters: string[] = [];
  public imageFilters: string[] = [];
  public isSubmitted: boolean = false;
  public imageLoading: any = {};
  public step: UploadStepModel;
  public chassisStep: UploadStepModel;
  quoteCustomerFormGroup: FormGroup;
  quoteVehicleFormGroup: FormGroup;
  caseGuid: string = ''; 
  infoReRequested: boolean = false;
  @ViewChild('photos') photos: NewPhotosComponent;
  @ViewChild('newDocuments') newDocuments: NewDocumentsComponent;
  @ViewChild('videoUploader') videoUploader: VideoUploaderComponent;
  public newDocumentHeader: ChqWidgetsHeaderModel = {
    title: 'add_documents',
    step: 3,
    description: 'add_documents_msg',
    stepName: 'step_three',
    isFnolModule: true,
    isHeaderOnly: true
  };
  userData:any;
  videoUrl: string = '';
  videoStatus: string = '';
  videoInspection:any;
  caseDetails: any = {};
  domainId: any = '';
  token: string = ''
  private modelList: any[] = [];
  private vehicleTypeList: any[] = [];
  public photosHeader: ChqWidgetsHeaderModel = {
    title: 'add_damage_photos',
    step: 4,
    description: 'damage_photos_msg',
    rightActionText: 'delete_all_start_over',
    rightActionIcon: 'delete',
    stepName: 'step_four',
    isFnolModule: true,
    showTitle: true
  };

  public customerDetails = [];

  public vehicleControls = [ ];

  private vinDetail: any = null;
  private previousVinCode: string = '';
  
  private makeList: any[] = [];
  private initialLoad: boolean = false;
  public countryList: any[] = [];
  sendRequest: number;

  /**
   * constructor
   */
  constructor(private claimService: ClaimsService,
    private dynamicFormService: DynamicFormService,
    private vehicleService: VehicleService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private quoteManagementService: QuoteManagementService,
    private monitorService: MonitorService,
    private inspectionService: InspectionService,
  ) {
    this.commonService.userProfileData.subscribe((userData) => {
      if (userData?.data) {
        this.userData = userData.data;
      }
    })
  }

  /**
   * Get vin call 
   * @param vin - vin number
   */
  loadVinDecoder(vin:string):void{
    const gtVinPermission = true; //this.commonService.checkOrgPackagesPermission(this.userPermission?.orgPackages, 'Vehicle Identification', 'gt.data.vincoding');
    const xaVinPermission = true; //this.commonService.checkOrgPackagesPermission(this.userPermission?.orgPackages, 'Vehicle Identification', 'xa.data.vincoding');
    if (gtVinPermission || xaVinPermission) {
      this.commonService.showLoading();
      this.vehicleService.getVinDecoder(vin)?.subscribe({
        next: (result: any) => {
          if (result?.success && result?.data) {
            this.vinDetail = result.data
            if (this.vinDetail) {
              setTimeout(() => {
                const selectedMake = this.makeList.find(q => q.name === this.vinDetail.vehicleMake);

                this.quoteCustomerFormGroup.get('makeID').setValue(selectedMake?.id);
              });
            }

            this.commonService.hideLoading();
          } else {
            this.vinDetail = null;
            if (this.quoteCustomerFormGroup?.get('makeID')?.value) {
              this.quoteCustomerFormGroup?.get('makeID').setValue('');
            }

            if (this.quoteCustomerFormGroup?.get('modelID')?.value) {
              this.quoteCustomerFormGroup?.get('modelID').setValue('');
              this.quoteCustomerFormGroup.updateValueAndValidity();
            }


            this.commonService.showInfoToast(5000, 'show-info | ' + result?.message);

            this.commonService.hideLoading();
            /*this.enableFields();
            this.controls = [ ...this.controls ];*/
          }
        }
      });
    } else {
      /*this.enableFields();
      this.controls = [ ...this.controls ];*/
    }
    
  }

  /**
   * Subscribe to dynamic form options and populate the dropdown values.
   * This will set the selected values for country, make, model and vehicle type.
   * When the quote assessment ID is not 0, it will prefill the form with the existing values.
   */
  populateDropdown(): void {
    this.dynamicFormService.updatedOption.subscribe((option) => {
      if (option?.item === 'code') {
        this.countryList = option.values;
        if(this?.caseDetails?.driverDetails?.countryCode) {
          const selectedCountry = this.countryList.find(q => q.phoneCountryCode === this.caseDetails.driverDetails.countryCode);
          this.quoteCustomerFormGroup.get('code').setValue(selectedCountry?.phoneCountryCode);
        }
       
      } else if (option?.item === 'makeID') {
        this.makeList = option.values;
        if(this.caseDetails?.vehicleMetaData?.makeId) {
          const selectedMake = this.makeList.find(q => q.id === this.caseDetails.vehicleMetaData.makeId);
          this.quoteVehicleFormGroup.get('makeID').setValue(selectedMake?.id);
        }
      } else if (option?.item === 'modelID') {
        this.modelList = option.values;
        this.makeList = option.values;
        if(this.caseDetails?.vehicleMetaData?.modelId) {
          const selectedModel = this.makeList.find(q => q.id === this.caseDetails.vehicleMetaData.modelId);
          this.quoteVehicleFormGroup.get('modelID').setValue(selectedModel?.id);
        }
      } else if (option?.item === 'vehicleType') {
        this.vehicleTypeList = option.values;
        if(this.caseDetails?.vehicleMetaData?.vehicleTypeId) {
          const selectedVehicle = this.vehicleTypeList.find(q => q.id === this.caseDetails.vehicleMetaData.vehicleTypeId);
          this.quoteVehicleFormGroup.get('vehicleType').setValue(selectedVehicle?.id);
        }
      }
    });
  }

  /**
   * isPhotosSectionValid
   */
  isPhotosSectionValid(): boolean {
    let isValid = true
    this.newPhoto?.imageUploader?.reviewSection?.steps?.forEach((ele: any) => {
      if(!ele.url && this.imageFilters.indexOf(ele.name) !== -1) isValid = false
    })
    return isValid
  }

  /**
   * isDocumentSectionValid
   */
  isDocumentSectionValid(): boolean {
    let isValid = true
    this.newDocuments?.imageUploader?.reviewSection.steps?.forEach((ele: any) => {
      if(!ele.uploadedGuid && this.documentFilters.indexOf(ele.parentName) !== -1) isValid = false
    })
    return isValid
  }

  /**
   * isVideoValid
   */
  isVideoValid(): boolean {
    let isValid = true
    if(this.captureVideo && !this.videoUrl && !this.videoUploader?.localVideoUrl) {
      isValid = false
    }
    return isValid
  }

  /**
   * isVideoValid
   */
  customerFormValid(): boolean {
    let isValid = true
    if(this.quoteCustomerFormGroup && !this.quoteCustomerFormGroup.valid) {
      isValid = false
    }
    return isValid
  }

  /**
   * isVideoValid
   */
  vehicleFormValid(): boolean {
    let isValid = true
    if(this.quoteVehicleFormGroup && !this.quoteVehicleFormGroup.valid) {
      isValid = false
    }
    return isValid
  }

  /**
   * submit form
   */
  submitForm():void{
    if (!this.customerFormValid() || !this.vehicleFormValid() || !this.isPhotosSectionValid() || !this.isDocumentSectionValid() || !this.isVideoValid()) {
      this.commonService.showToast(0, 'Required fields are missing or invalid');
      return;
    }
    const existingPayload = {
      'QuoteNo': this.caseDetails.quoteNo,
      'InspectionId': this.caseDetails.inspectionId,
      'DomainId': this.caseDetails.domainID,
      'VehicleMetaData': {
        'Make': this.caseDetails?.vehicleMetaData?.make,
        'MakeId': this.caseDetails?.vehicleMetaData?.makeId,
        'Model': this.caseDetails?.vehicleMetaData?.model,
        'ModelId': this.caseDetails?.vehicleMetaData?.modelId,
        'Year': this.caseDetails?.vehicleMetaData?.year,
        'VinNum': this.caseDetails?.vehicleMetaData?.vinNum,
        'VehicleName': this.caseDetails?.vehicleMetaData?.vehicleName,
        'PlateNo': this.caseDetails?.vehicleMetaData?.plateNo,
        'RegistrationDate': this.caseDetails?.vehicleMetaData?.registrationDate,
        'ManufacturingDate': this.caseDetails?.vehicleMetaData?.manufacturingDate,
        'VehicleTypeId': this.caseDetails?.vehicleMetaData?.vehicleTypeId,
        'VehicleType': this.caseDetails?.vehicleMetaData?.vehicleType,
        'Mileage': this.caseDetails?.vehicleMetaData?.mileage,
        'Id': this.caseDetails?.vehicleMetaData?.id,
      },
      'DriverDetails': {
        'FirstName': this.caseDetails?.driverDetails?.firstName,
        'LastName': this.caseDetails?.driverDetails?.lastName,
        'PhoneNumber': this.caseDetails?.driverDetails?.phoneNumber,
        'CountryCode': this.caseDetails?.driverDetails?.countryCode,
        'Email': this.caseDetails?.driverDetails?.email,
        'Id': this.caseDetails?.driverDetails?.id,
      },
      'CommunicationType': this.caseDetails?.communicationType || [],
      'CaseGuid': this.caseDetails?.caseGuid,
      'Id': this.caseDetails?.id
    }
    if(this.quoteCustomerFormGroup?.value) {
      const customerValues = this.quoteCustomerFormGroup.value;
      if(customerValues.firstName) existingPayload.DriverDetails.FirstName = customerValues.firstName;
      if(customerValues.lastName) existingPayload.DriverDetails.LastName = customerValues.lastName;
      if(customerValues.email) existingPayload.DriverDetails.Email = customerValues.email;
      if(customerValues.code) existingPayload.DriverDetails.CountryCode = customerValues.code;
      if(customerValues.mobileNumber) existingPayload.DriverDetails.PhoneNumber = customerValues.mobileNumber;
    }
    
    if(this.quoteVehicleFormGroup?.value) {
      const vechileValues = this.quoteVehicleFormGroup.value;
      if(vechileValues.makeID) {
        existingPayload.VehicleMetaData.MakeId = vechileValues.makeID
        existingPayload.VehicleMetaData.Make = this.makeList.find(q => q.id === vechileValues.makeID)?.name || ''
      }
      if(vechileValues.modelID) {
        existingPayload.VehicleMetaData.ModelId = vechileValues.modelID
        existingPayload.VehicleMetaData.Model = this.modelList.find(q => q.id === vechileValues.modelID)?.modelName || ''
      }
      if(vechileValues.vin) {
        existingPayload.VehicleMetaData.VinNum = vechileValues.vin
      }
      existingPayload.VehicleMetaData.VehicleName = existingPayload.VehicleMetaData.Make + existingPayload.VehicleMetaData.Model 
      if(vechileValues.plateNumber) {
        existingPayload.VehicleMetaData.PlateNo = vechileValues.plateNumber
      }
      if(vechileValues.registrationDate) {
        existingPayload.VehicleMetaData.RegistrationDate = dayjs(vechileValues.registrationDate).format('YYYY-MM-DDT00:00:00')
      }
      if(vechileValues.manufacturingDate) {
        existingPayload.VehicleMetaData.ManufacturingDate = dayjs(vechileValues.manufacturingDate).format('YYYY-MM-DDT00:00:00')
      }
      if(vechileValues.vehicleType) {
        existingPayload.VehicleMetaData.VehicleTypeId = vechileValues.vehicleType
        existingPayload.VehicleMetaData.VehicleType = this.vehicleTypeList.find(q=> q.id == vechileValues.vehicleType)?.name || ''
      }
    }
    
    this.commonService.showLoading();
    this.quoteManagementService.updateQuoteCase(existingPayload, this.caseGuid).subscribe({
      next: (result: any) => {
        this.quoteManagementService.updateCaseStatus({
          Status: CASE_STATUS.CustomerSubmitted
        }, this.caseGuid).subscribe({
          next: (result: any) => {
            this.commonService.hideLoading();
            if(result) {
              this.isSubmitted = true;
              localStorage.setItem(this.token, 'submitted');
            }
          },
          error: (err: any) => {
            this.commonService.hideLoading();
            this.monitorService.logException(err);
          }
        });
      },
      error: (err: any) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err);
      }
    });
  }

  /**
   * initialize vechile /customer form
   * 
   */
  initializeVehicleCustomerForm():void{
    const controls = quoteAssessmentCustomerForm.map((it) => { return this.dynamicFormService.controlMapper(it, this.userData) });

    const keys = this.customerDetails.map(it => it.name);
    const vehicleKeys = this.vehicleControls.map(it => it.name);
    const customerControls = controls.filter(x=>keys.includes(x.key));
    const vehicleControls = controls.filter(x=>vehicleKeys.includes(x.key));
  
    if(keys.includes('mobileNumber')){
      customerControls.push(controls.find(x=>x.key === 'code'));
    }

    if(!vehicleKeys.includes('vin')){
      vehicleControls.forEach((ele) => {
        if(ele.key == 'makeID') ele.conditions = []
      })
    }

    this.controls = this.dynamicFormService.getSortedFormControls(customerControls);
    this.vehicleCtrlList = this.dynamicFormService.getSortedFormControls(vehicleControls);
    this.quoteCustomerFormGroup = this.dynamicFormService.createFormGroup(this.controls);
    this.quoteVehicleFormGroup = this.dynamicFormService.createFormGroup(this.vehicleCtrlList);

    if(keys.includes('mobileNumber')){
      this.phoneNumberValidation();
    }

    if(vehicleKeys.includes('vin')){
      this.quoteVehicleFormGroup.get('vin')?.valueChanges.subscribe((res)=>{
        if (res.length == 17 && this.previousVinCode != res && this.initialLoad) {
          this.previousVinCode = res;
          this.loadVinDecoder(res);
        }
  
        this.initialLoad = true;
      })
    }
    this.populateDropdown();
    //TODO pre populate data 
    if(this.quoteCustomerFormGroup?.value) {
      const patchForCustomer:any = {};
      Object.keys(this.quoteCustomerFormGroup.value).forEach((ele: any) => {
        if(ele === 'firstName' && this.caseDetails?.driverDetails?.firstName) patchForCustomer.firstName = this.caseDetails.driverDetails.firstName;
        if(ele === 'lastName' && this.caseDetails?.driverDetails?.lastName) patchForCustomer.lastName = this.caseDetails.driverDetails.lastName;
        if(ele === 'code' && this.caseDetails?.driverDetails?.countryCode) patchForCustomer.code = this.caseDetails.driverDetails.countryCode;
        if(ele === 'mobileNumber' && this.caseDetails?.driverDetails?.firstName) patchForCustomer.mobileNumber = this.caseDetails.driverDetails.phoneNumber;
        if(ele === 'email' && this.caseDetails?.driverDetails?.firstName) patchForCustomer.email = this.caseDetails.driverDetails.email;
      })
      this.quoteCustomerFormGroup.patchValue(patchForCustomer);
    }
    if(this.quoteVehicleFormGroup?.value) {
      const patchForVehicle:any = {};
      Object.keys(this.quoteVehicleFormGroup?.value).forEach((ele: any) => {
        if(ele === 'manufacturingDate' && this.caseDetails?.vehicleMetaData?.manufacturingDate) patchForVehicle.manufacturingDate = this.caseDetails.vehicleMetaData.manufacturingDate;
        if(ele === 'plateNumber' && this.caseDetails?.vehicleMetaData?.plateNo) patchForVehicle.plateNumber = this.caseDetails.vehicleMetaData.plateNo;
        if(ele === 'registrationDate' && this.caseDetails?.vehicleMetaData?.registrationDate) patchForVehicle.registrationDate = this.caseDetails.vehicleMetaData.registrationDate;
        if(ele === 'vin' && this.caseDetails?.vehicleMetaData?.vinNum) patchForVehicle.vin = this.caseDetails.vehicleMetaData.vinNum;
      })
      this.quoteVehicleFormGroup.patchValue(patchForVehicle);
    }
  }

  /**
   * Get missing info from api
   * This function is called when the component is initialized. It calls the getMissingInfo
   * function from the ClaimsService to get the missing information from the API.
   * The response is logged in the console for debugging purposes.
   */
  ngOnInit(): void {
    const token = this.route.snapshot.queryParams['token'];
    this.token = token;
    const status = localStorage.getItem(this.token);
    if(status === 'submitted'){
      this.isSubmitted = true;
      return
    }

    const obj: any = atob(token);
    const parsedObj = JSON.parse(obj);
    this.caseGuid = parsedObj.CaseGuid;
    this.commonService.showLoading();
    let isVehicleCustomerInfoRequired = false;
    forkJoin([
      this.quoteManagementService.getCaseDetail(this.caseGuid),
      this.quoteManagementService.getMissingInfo(this.caseGuid, parsedObj.DomainId)
    ]
    ).pipe(
      map(([ caseDetails, missingInfoResp ]) => {
        return { caseDetails, missingInfoResp };
      })
    ).subscribe((result) => {
      this.caseDetails = result.caseDetails?.data || { };
      this.checkRequestPermission(this.caseDetails.caseStatus);
      const { missingFieldsDTO } = result.missingInfoResp.data;
      this.objectId = this.caseDetails?.id;
      this.GUID = this.caseDetails?.caseGuid;
      this.domainId = this.caseDetails?.domainID;
      missingFieldsDTO?.forEach((field: any) => {
        if (field.key === 'Photos' && field.values?.length > 0) {
          this.inspectionId = this.caseDetails?.inspectionId;
          const video = field.values.find((video: any) => video.name?.toLowerCase() === 'video');
          this.imageFilters = field.values.map((x: any) => x.name);
          if (video) {
            this.captureVideo = true;
          }
        }
        if (field.key === 'Documents' && field.values?.length > 0) {
          this.showDocument = true;
          this.documentFilters = field.values.map((x: any) => x.name);
        }
        if (field.key === 'Vehicle Details') {
          this.vehicleControls = field.values;
          isVehicleCustomerInfoRequired = true;
        }
        if (field.key === 'Customer Details') {
          this.customerDetails = field.values;
          isVehicleCustomerInfoRequired = true;
        }
      });

      if (isVehicleCustomerInfoRequired) {
        this.initializeVehicleCustomerForm();
      }

      this.commonService.hideLoading();
    });
  }

  /**
   * handle step load
   */
  stepLoad(steps: UploadStepModel[], module: string): void {
    if (module === 'photos') {
      this.step = steps.find(x => x.stepName.includes('video'));
    } else {
      this.chassisStep = steps.find(x => x.stepName.includes('chassis'));
    }
  }

  /**
   * phone number validation
   */
  phoneNumberValidation(): void {
    this.quoteCustomerFormGroup.get('code')?.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([ prev, next ]: [any, any]) => {
        if (prev !== next && next) {
          const country = this.countryList.find(country => country.phoneCountryCode === next);
          if (country) {
            const mobileNoControl = this.controls.find(control => control.key === 'mobileNumber');
            mobileNoControl.disabled = false;
            const patternValidator = mobileNoControl.validators.find(validator => validator.type === 'pattern');
            patternValidator.regex = country.regExpression;
            this.quoteCustomerFormGroup.get('mobileNumber').setValue('');
            this.quoteCustomerFormGroup.get('mobileNumber').clearValidators();
            this.quoteCustomerFormGroup.get('mobileNumber').setValidators(this.dynamicFormService.createValidators(mobileNoControl));
            this.controls = [ ...this.controls ];
          }
        } 
      });
  }

  /**
 * set loading
 */
  setImageLoading(name: string): void {
    if (this.imageLoading[name]) {
      return;
    }
    this.imageLoading[name] = true;
  }

  /**
   * handle review action
   */
  // handleReviewAction(step: UploadStepModel, action: string): void {
  //   if (this.photos) {
  //     this.photos?.imageUploader?.reviewSection[action](step)
  //   }
  // }

  /**
   * store tags
   */
  handleOcrTags($event: any): void {
    if ($event.step === 'country') {
      this.countryId = $event.value;
      return;
    }

    if ($event.step === 'documentCollectionId') {
      this.documentCollectionId = $event.value;
      return;
    }

    if ($event.step.toLowerCase().includes('vehicle registration')) {
      this.vehicleRegistrationOcrTagMap[$event.step] = $event.tags ?? [];
      return;
    }

    if ($event.step.toLowerCase().includes('driving license')) {
      this.drivingOcrTagsMap[$event.step] = $event.tags ?? [];
      return;
    }
  }

  /**
   *videoInspectionItemDetails
   */
  videoInspectionItemDetails(event: any): void {
    this.videoUrl = event?.inspectionItem?.rawAzureBlobUrl;
    this.videoStatus = 'IsUploadSuccess';
  }

  /**
   * upload video
   * @param currentStep 
   */
  uploadFile(currentStep: UploadStepModel): void {
    const uploadObject = {
      'height': currentStep.imageHeight,
      'width': currentStep.imageWidth,
      'inspectionId': this.inspectionId,
      'templateId': currentStep.inspectionItem?.inspectionItemTemplateID,
      'inspectionitemId': currentStep.inspectionItem.id,
      'type': '',
      'isBulk': false
    }

    this.monitorService.logEvent('uploadFile', [ 'ChqNewPhotosComponent', 'addenda-quote', {
      uploadObject
    } ]);

    currentStep.uploadInProgress = true;
    currentStep.isSkipped = false;
    currentStep.isUploadFailed = false;
    currentStep.isUploadSuccess = false;
    this.videoStatus = 'UploadInProgress';
    this.commonService.showNotification(`Your 360 video is currently uploading won’t be long and we’ll notify
       you when its uploaded.`);
    this.commonService.showLoading()
    this.inspectionService.uploadImageBulk(uploadObject, currentStep.imageFile).subscribe({
      next: (data: any) => {
        this.commonService.hideLoading()
        currentStep.uploadInProgress = false;
        currentStep.isUploadSuccess = true;
        this.videoStatus = 'IsUploadSuccess';
        this.videoUrl = data.rawImage?.rawAzureBlobUrl;
        currentStep.url = data.rawImage?.rawAzureBlobUrl;
        const msg = 'Your 360 video is uploaded successfully.';
        this.commonService.showNotification(msg);
      },
      error: (err: any) => {
        this.commonService.hideLoading()
        if (err.status != 401) {
          const msg = 'Your 360 video failed to upload. Please try to upload again.';
          this.commonService.showNotification(msg);
          this.videoStatus = 'IsUploadFailed';
          this.videoUrl = '';
          currentStep.uploadInProgress = false;
          currentStep.isUploadFailed = true;
          currentStep.isUploadSuccess = false;
        }
      },
    })
  }

  /**
   * handle video file change
   * @param event 
   */
  handleFileChange(event: any): void {
    this.videoInspection = this.newPhoto.videoInspectionItemTemplate;
    if(event.action === 'add'){
      this.videoInspection.imageFile = event.file;
      this.uploadFile(this.videoInspection);
    }else{
      this.newPhoto.imageUploader.reviewSection.deleteImage(this.videoInspection);
    }

  }

  /**
   * return 
   * @param caseStatus 
   */
  checkRequestPermission(caseStatus): void {
    const status = caseStatus.toLowerCase();
    if (status === 'draft' || status === 'pending information') {
      this.sendRequest = 2;
    } else if (status === 'customer submitted' || status === 'in review') {
      this.sendRequest = 1;
    } else {
      this.sendRequest = 0;
    }
  }
}
