import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { FooterModel, Modules, UploadStepModel, uploadEventResult } from 'src/app/model/chq-upload-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ChqUploadComponent } from '../chq-upload/chq-upload.component';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { Platform } from '@angular/cdk/platform';
import { CLAIM_STATUS } from 'src/app/config/constants/claims.constants';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { ChqReviewSectionComponent } from '../chq-review-section/chq-review-section.component';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';

@Component({
  selector: 'chq-images-uploader',
  templateUrl: './chq-images-uploader.component.html',
  styleUrls: [ './chq-images-uploader.component.scss' ]
})
export class ChqImagesUploaderComponent implements OnInit {
  @Input() header: ChqWidgetsHeaderModel;
  @Input() footerOptions: FooterModel;
  @Input() showReTakeDelete: boolean;
  @Input() isMassUpload: boolean;
  @Input() inspection: string;
  @Input() inspectionId: number;
  @Input() isRepairStage: boolean;
  @Input() hideAI: boolean;
  @Input() minimumImagesRequired: number = 0;
  @Input() maximumImages: number;
  @Input() filteredSteps: string[] = [];
  @Input() displayOutline:boolean;
  @Input() submitSection:string;
  public isScrolling: any;
  @Input() currentAdditionalModeMultiple: boolean;
  @Input() fileUploaderService: (currentStep: UploadStepModel) => void;
  @Output() updateMassUpload: EventEmitter<any> = new EventEmitter();
  @Output() cardSelection: EventEmitter<any> = new EventEmitter();
  @Output() backToUpload: EventEmitter<any> = new EventEmitter();
  @Output() handleDeleteAllEvent: EventEmitter<any> = new EventEmitter();
  @Output() stepSkipped: EventEmitter<any> = new EventEmitter();

  @Input() showNotify: boolean;
  @Input() showComplete: boolean;
  @Input() activateRepairButton: boolean;
  @Input() isRepairComplete: boolean;

  @Output() notify: EventEmitter<any> = new EventEmitter();
  @Output() completeRepair: EventEmitter<any> = new EventEmitter();

  @Output() completeUploadProcess: EventEmitter<any> = new EventEmitter();
  @Output() damageAnalysisComplete: EventEmitter<any> = new EventEmitter();
  @Output() updateAiEstimateStatus: EventEmitter<any> = new EventEmitter();
  @ViewChild('reviewSection') reviewSection: ChqReviewSectionComponent;
  public totalAdditionalSteps: number = 0;
  hideHeader: boolean = false;

  public previewPDFButtonModel: ChqWidgetsButtonModel = {
    icon: 'attachment',
    label: 'Preview PDF',
    type: 'outline'
  }
  public skipButtonModel: ChqWidgetsButtonModel = {
    label: 'skip',
    type: 'primary'
  }

  public uploadButtonModel: ChqWidgetsButtonModel = {
    label: 'upload',
    type: 'primary'
  }

  public retakeButton: ChqWidgetsButtonModel = {
    label: 'retake',
    type: 'primary'
  }

  public notifyButtonModel: ChqWidgetsButtonModel = {
    label: 'notify',
    type: 'outline',
    icon: 'send',
    onclick: () => {
      this.notify.emit()
    }
  }

  public backButtonModel: ChqWidgetsButtonModel = {
    label: 'back_to_photo',
    type: 'outline'
  }

  public deleteButton: ChqWidgetsButtonModel = {
    label: 'delete',
    type: 'red'
  }

  public bookButton: ChqWidgetsButtonModel = {
    label: 'book_in',
    type: 'primary'
  }

  public cancelButtonModel: ChqWidgetsButtonModel = {
    label: 'Cancel Booking',
    type: 'outline-danger',
    onclick: this.cancelBooking.bind(this)
  }

  public imageLoading: any = {};

  @ViewChild('chqUpload') chqUpload: ChqUploadComponent;
  public fromReviewPage: boolean = false;
  public currentStep: UploadStepModel;
  public isReviewSection: boolean = false;
  public hideBack: boolean = false;
  public currentStepIndex = 0;

  public currentNonReviewSteps = 0;

  @Input() imageStatusSteps: UploadStepModel[] = [];

  public skipAllButtonModel: ChqWidgetsButtonModel = {
    label: 'Skip All',
    type: 'outline'
  }

  /**
   * show delete
   */
  openPDFinNewTab(url: string): void {
    window.open(url, '_blank');
  }
  /**
   * show delete
   */
  get showDelete(): boolean {
    if (this.footerOptions?.repairId && this.footerOptions?.currentStatus?.toLowerCase() === 'draft') {
      return true;
    }

    return false;
  }

  @Input() sendRequest: number;

  /**
   * constructor
   * @param fb ChangeDetectorRef
   * @param CommonService
   * @param inspectionService
   * @param monitorService
   * @param route
   * @param router
   * @param calendarService
   * @param claimService
   * @param platform
   */
  constructor(
    private cd: ChangeDetectorRef,
    public commonService: CommonService,
    private inspectionService: InspectionService,
    private monitorService: MonitorService,
    private route: ActivatedRoute,
    private router: Router,
    private calendarService: CalendarService,
    private claimService: ClaimsService,
    private configurationService: ConfigurationService,
    private quoteAssessmentService: QuoteManagementService,
    public platform: Platform) { }

  /**
   * handles image upload
   * @param event
   */
  handleImageOutput($event: uploadEventResult | uploadEventResult[]): void {
    this.monitorService.logEvent('handleImageOutput', [ 'ChqImagesUploaderComponent', 'addenda-quote', {
      $event
    } ]);

    if (this.isMassUpload || this.currentAdditionalModeMultiple) {
      this.currentAdditionalModeMultiple = true;
      const events = $event as uploadEventResult[];
      let index = 0;
      for (const i in events) {
        const fileResult = events[i];
        const stepObj: UploadStepModel = {
          stepName: fileResult.fileName,
          uploadStatus: 'notUploaded',
          url: '',
          image: fileResult.image,
          imageFile: fileResult.file,
          index,
          sizeLimit: 5242880,
          isMassUpload: true,
          allowExtension: [ 'jpg', 'jpeg', 'png', 'pdf' ],
          mandatory: true,
          placeHolderUrl: '',
          isUploadSuccess: false,
          uploadInProgress: false,
          isUploadFailed: false,
          isSkipped: false,
          templateGuid: '',
          partTemplateGuid: ''
        }
        index++;
        this.imageStatusSteps.push(stepObj);
      }
      if (this.isMassUpload) {
        this.updateMassUpload.emit({
          steps: this.imageStatusSteps.filter((s: UploadStepModel) => s.isUploadSuccess != true
            && s.uploadInProgress !== true)
        });
      } else {
        this.updateMassUpload.emit({ steps: [ this.imageStatusSteps[this.imageStatusSteps.length - 1] ] });
      }

      if (this.nonAdditional < (this.maximumImages || 20)) {
        const additionalStep = this.imageStatusSteps.filter((st: UploadStepModel) => {
          return st.index === -1 && st.isAdditionalDocumentFromReviewPageOnly === true;
        });
        if (additionalStep.length === 0) {
          const currentStep: UploadStepModel = {
            stepName: this.inspection === 'photos' ? 'Additional Image' : 'Additional Documents',
            uploadStatus: 'notUploaded',
            url: '',
            image: '',
            index: -1,
            isAdditionalDocumentFromReviewPageOnly: true,
            sizeLimit: 5242880,
            allowExtension: [ 'jpg', 'jpeg', 'png', 'pdf' ],
            mandatory: false,
            isUploadSuccess: false,
            uploadInProgress: false,
            isUploadFailed: false,
            isSkipped: false,
            templateGuid: '',
            partTemplateGuid: '',
            placeHolderUrl: '/assets/icons/additional-image.svg'
          }
          this.imageStatusSteps.push(currentStep);
        }

      } else {
        this.imageStatusSteps = this?.imageStatusSteps?.filter((currentStep: UploadStepModel) => {
          return (currentStep.isAdditionalDocumentFromReviewPageOnly != true || currentStep.image || currentStep.url);
        });
        this.imageStatusSteps[this.imageStatusSteps.length - 1].isAdditionalDocumentFromReviewPageOnly = false;

      }
      this.imageStatusSteps = [ ...this.imageStatusSteps ];
      this.isReviewSection = true;
      this.fromReviewPage = false;
    } else {
      const event = $event as uploadEventResult;

      if (this.currentStep.isAdditionalDocumentFromReviewPageOnly) {
        const currentAdditionalStep = this.imageStatusSteps.filter((step: UploadStepModel) => {
          return step.isAdditionalDocumentFromReviewPageOnly === true && step.uploadInProgress != true;
        });
        this.currentStep = currentAdditionalStep[0];
      }
      this.currentStep['image'] = event.image;
      this.currentStep.imageFile = event.file;
      this.currentStep.contentType = event?.file?.type;
      this.currentStep.fileName = event.fileName;
      this.imageStatusSteps = [ ...this.imageStatusSteps ];

      if (this.inspection == 'photos') {
        const img = new Image();
        img.src = event.image as string;
        img.onload = (): void => {
          this.currentStep.imageHeight = img.height;
          this.currentStep.imageWidth = img.width;
          this.executeUploader();
          //show review page only if all the images have been uploaded
          if ((this.imageStatusSteps.filter(q => q => q.isUploadSuccess !== true && q.mandatory)?.length == 0) || this.fromReviewPage ||
            this.currentStep.isAdditionalDocumentFromReviewPageOnly) {
            this.isReviewSection = true;
            this.fromReviewPage = false;
          } else {
            this.isReviewSection = false;
            this.currentStepIndex++;

            if (this.currentStepIndex > this.imageStatusSteps.length - 1) {
              this.currentStepIndex--;
            }

            const selectedStep = this.imageStatusSteps[this.currentStepIndex];
            this.currentStep = selectedStep;
          }
        }


      } else {
        this.executeUploader();
        if (this.currentStepIndex === (this.imageStatusSteps.length) || (this.totalAdditionalSteps === 0 &&
          this.currentStepIndex === (this.imageStatusSteps.length - 1)) || this.currentStep.isAdditionalDocumentFromReviewPageOnly
          || this.fromReviewPage) {
          this.isReviewSection = true;
          this.fromReviewPage = false;
          this.checkRouteParams();
        } else {
          this.isReviewSection = false;
          this.currentStepIndex++;
          const selectedStep = this.imageStatusSteps[this.currentStepIndex];
          this.currentStep = selectedStep;
          this.cardSelection.emit(this.currentStep);
        }
      }

    }

  }


  /**
   * check route params
   */
  checkRouteParams(): void {
    this.route.queryParams?.subscribe((params) => {
      const codeParam = params['fromPersonal']
      if (codeParam === 'true' || codeParam === true) {
        this.router.navigate([ '/repair/booking/0' ])
      } else {
        this.isReviewSection = true;
        this.fromReviewPage = false;
      }
    });
  }

  /**
   * handle scroll
   */
  onScroll(): void {
    window.clearTimeout(this.isScrolling);
    const footer = document.getElementsByClassName('footer-full-wrapper');
    if (footer && footer.length > 0) {
      (footer[0] as HTMLElement).style.position = 'absolute';
    }
    this.isScrolling = setTimeout(() => {
      const footer = document.getElementsByClassName('footer-full-wrapper');
      if (footer && footer.length > 0) {
        (footer[0] as HTMLElement).style.position = 'fixed';
      }
    }, 66);
  }


  /**
   * multiple upload filter
   */
  get nonAdditional(): number {
    return this?.imageStatusSteps?.filter((currentStep: UploadStepModel) => {
      return (currentStep.isAdditionalDocumentFromReviewPageOnly != true || currentStep.image || currentStep.url);
    }).length;
  }

  /**
   * go to Review
   */
  goToReview(): void {
    this.isReviewSection = true;
    this.fromReviewPage = false;
    this.monitorService.logEvent('goToReview', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);
  }

  /**
   * handle delete all
   */
  handleAllImagesDelete(): void {
    this.imageStatusSteps = [];
    this.handleDeleteAllEvent.emit();
  }

  /**
   * execute observable
   */
  executeUploader(): void {
    //
    if (!this.fileUploaderService) {
      return;
    }
    const messageForUploading = `Your ${this.inspection == 'photos' ? 'photo' : 'document'} is currently uploading won’t be long and we’ll
    notify you when its uploaded, you can continue to take your ${this.inspection == 'photos' ? 'photos' : 'documents'}.`
    this.commonService.showNotification(messageForUploading);
    this.fileUploaderService(this.currentStep);
    this.monitorService.logEvent('executeUploader', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);

    setTimeout(() => {
      const inprogressPhotos = this.imageStatusSteps.filter((currentStep: UploadStepModel) => {
        return currentStep.uploadInProgress === true;
      });
      if (inprogressPhotos.length > 0) {
        this.hideBack = true;
      }
      this.commonService.inProgressSteps.next(inprogressPhotos.length);
    })
  }

  /**
   * handle Review action
   */
  handleReviewAction($event: any): void {
    this.monitorService.logEvent('handleReviewAction', [ 'ChqImagesUploaderComponent', 'addenda-quote', {
      $event
    } ]);

    if ((this.footerOptions?.repairId && [ 'edit', 'upload' ].includes($event.mode) && this.footerOptions?.from == Modules.quote && this.commonService.repairPermission)
      || (this.footerOptions?.caseId && [ 'edit', 'upload' ].includes($event.mode) && this.footerOptions?.from == Modules.repair && this.commonService.quotePermission)) {
      this.commonService.showChangeWarningMessage(this.handleReviewActionCall.bind(this, $event),
        this.footerOptions.from == 'Quote' ? 'Repair' : 'Quote');
    } else {
      this.handleReviewActionCall($event);
    }
  }


  /**
   * handle review action call
   * @param $event
   */
  handleReviewActionCall($event: any): void {

    if ($event.mode === 'edit') {
      this.setCurrentStep($event.step);
      this.isReviewSection = false;
      this.fromReviewPage = true;
    } else if ($event.mode === 'upload') {
      this.setCurrentStep($event.step);
      this.fromReviewPage = true;
      this.uploadFile($event.type);
    } else {
      if ($event.step.inspectionItem) {
        const index = this.imageStatusSteps.findIndex((ex: UploadStepModel) => {
          return ex.inspectionItem?.id === $event.step.inspectionItem?.id;
        });
        this.deleteSelectedImage($event.step, index);
      } else {
        const index = $event.step.index;
        this.deleteSelectedImage(this.imageStatusSteps[index], index);
      }
    }
  }

  /**
   * update deleted image
   */
  deleteSelectedImage(currentStep: UploadStepModel, index: number): void {

    currentStep.contentType = '';
    currentStep.url = '';
    currentStep.thumbnailUrl = '';
    currentStep.uploadedGuid = '';
    currentStep.updatedDate = '';
    currentStep.uploadedDate = '';
    currentStep.uploadInProgress = false;
    currentStep.isSkipped = false;
    currentStep.isUploadSuccess = false;
    currentStep.uploadedPartGuid = '';
    currentStep.isUploadFailed = false;
    currentStep.imageFile = null;
    currentStep.image = null;
    currentStep.uploadStatus = 'notUploaded';
    if (currentStep.stepName.indexOf('Additional') != -1) {
      currentStep.isAdditionalDocumentFromReviewPageOnly = true;
    }

    if (currentStep.isMassUpload || currentStep.procedureSteps === 'Bulk Upload Image') {
      this.imageStatusSteps = this.imageStatusSteps.filter((step: UploadStepModel) => {
        return step?.inspectionItem?.id != currentStep?.inspectionItem?.id;
      });
    } else {
      this.imageStatusSteps[index] = { ...currentStep };
      this.imageStatusSteps = [ ...this.imageStatusSteps ];
      const uploadedFiles = this.imageStatusSteps.filter((v: UploadStepModel) => {
        return v.image || v.url;
      });

      if (uploadedFiles.length === 0 && this.inspection === 'photos') {
        this.footerOptions.showUploadOption = true;
        this.hideBack = false;
        this.header = { ...this.header, showTitle: true };
      } else {
        this.header = { ...this.header, showTitle: false };
      }
    }

    const uploadedImagesCount = this.imageStatusSteps.filter((step: UploadStepModel) => {
      return step.url;
    }).length

    if (uploadedImagesCount === 0) {
      this.hideBack = false;
      this.setCurrentStep(this.imageStatusSteps[0]);
    }

  }

  /**
   * is case pending
   */
  get currentStatus(): boolean {
    if (!this.footerOptions?.currentStatus) {
      return true;
    }
    return [ 'closed', 'complete', CLAIM_STATUS.Complete.toLowerCase() ].indexOf(this.footerOptions?.currentStatus?.toLowerCase()) === -1;
  }

  /**
   * delete Uploaded Image
   */
  delete(): void {
    this.monitorService.logEvent('delete', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);

    if (this.currentStep.url && this.inspection === 'photos') {
      this.inspectionService.deleteInspection(this.currentStep.inspectionItem.id).subscribe((resp: any) => {
        this.commonService.hideLoading();
        const images = this.imageStatusSteps.filter((x: UploadStepModel) => {
          return x.image || x.url;
        });

        if ((this.header?.isClaimModule && !this.commonService.claimCreatedFromSurveyor)) {
          this.claimService.updateFraudDetection(this.footerOptions.claimId).subscribe();
        } else if (this.header?.isQuoteAssessmentModule) {
          this.quoteAssessmentService.updateFraudDetection(this.footerOptions.quoteAssessmentId).subscribe();
        }

        if (images.length === 0) {
          this.footerOptions.showUploadOption = true;
        }
      }, () => {
        this.commonService.hideLoading();
      })
    }
    this.currentStep.image = null;
    this.currentStep.uploadedGuid = '';
    this.currentStep.uploadInProgress = false;
    this.currentStep.isSkipped = false;
    this.currentStep.isUploadSuccess = false;
    this.currentStep.isUploadFailed = false;
    this.currentStep.imageFile = null;
    this.currentStep.url = '';
    this.currentStep.thumbnailUrl = '';
    this.currentStep.updatedDate = '';
    this.currentStep.uploadedDate = '';
    this.currentStep.uploadStatus = 'notUploaded';
    if (this.currentStep.stepName.indexOf('Additional') != -1) {
      this.currentStep.isAdditionalDocumentFromReviewPageOnly = true;
    }
    const uploadedImagesCount = this.imageStatusSteps.filter((step: UploadStepModel) => {
      return step.url;
    }).length

    if (uploadedImagesCount === 0) {
      this.hideBack = false;
      this.setCurrentStep(this.imageStatusSteps[0]);
    }
  }

  /**
   * update document status
   */
  updateUploadStatus(status: string, currentStep: UploadStepModel, err?: any, responseData?: any, isBulk?: boolean): void {
    const uploadedGuid = currentStep.uploadedGuid;
    const uploadedPartGuid = currentStep.uploadedPartGuid;
    this.monitorService.logEvent('updateUploadStatus', [ 'ChqImagesUploaderComponent', 'addenda-quote', {
      status,
      currentStep,
      responseData,
      isBulk
    } ]);
    if (this.inspection !== 'photos') {
      this.imageStatusSteps.forEach((el) => {
        if (el.partTemplateGuid === currentStep.partTemplateGuid) {
          el.url = responseData?.data?.blobUrl || responseData?.blobUrl
        }
      })
    }
    this.chqUpload.resetValue();
    if (status == 'failed') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadSuccess = false;
      currentStep.isUploadFailed = false;
      if (isBulk) {
        currentStep.isUploadFailed = true;
      } else {
        currentStep.imageFile = null;
        currentStep.image = null;
      }

      const message = (err && err.error?.msg) ? err.error?.msg
        : `Your photo ‘${currentStep.stepName}’ failed to upload. Please try to upload again.`
      const status = err ? err.status : 0;
      this.commonService.showToast(status, message);
      if (this.currentStep.isAdditionalDocumentFromReviewPageOnly) {
        const step: UploadStepModel = {
          stepName: this.inspection === 'photos' ? 'Additional Image' : 'Additional Documents',
          uploadStatus: 'notUploaded',
          url: '',
          image: '',
          index: -1,
          isAdditionalDocumentFromReviewPageOnly: true,
          sizeLimit: this.currentStep.sizeLimit,
          allowExtension: this.currentStep.allowExtension,
          mandatory: false,
          isUploadSuccess: false,
          uploadInProgress: false,
          isUploadFailed: false,
          isSkipped: false,
          isMassUpload: currentStep.isMassUpload,
          templateGuid: this.currentStep.templateGuid,
          partTemplateGuid: this.currentStep.partTemplateGuid,
          placeHolderUrl: '/assets/icons/additional-image.svg'
        }
        this.currentStep = step;
      }
    } else if (status == 'success') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadFailed = false;
      currentStep.isUploadSuccess = true;
      currentStep.uploadStatus = 'success';
      currentStep.updatedDate = dayjs().toISOString();
      currentStep.uploadedDate = dayjs().toISOString();
      currentStep.isAdditionalDocumentFromReviewPageOnly = false;
      if (this.inspection === 'photos') {
        currentStep.inspectionItem = responseData;
      }

      if (responseData) {
        if (currentStep.originalName) {
          currentStep.stepName = currentStep.originalName;
        }

        currentStep.uploadedGuid = responseData.mediaGuid;
        currentStep.uploadedPartGuid = responseData.mediaPartGuid;
      }
      this.updateAiEstimateStatus.emit({
        status: null
      });
    }
    this.imageStatusSteps = [ ...this.imageStatusSteps ];
    const uploaded = this.imageStatusSteps.filter((step: UploadStepModel) => {
      return step.uploadInProgress === true;
    });
    this.hideBack = true;
    this.commonService.inProgressSteps.next(uploaded.length);
    if (this.header?.isClaimModule || this.header?.isQuoteAssessmentModule) {
      if (uploaded.length === 0) {
        if (this.inspection == 'photos') {
          this.commonService.showInfoToast(5000, 'Please wait until all the images are AI analyzed to proceed to the next step or go to damage analysis to view the latest AI status.');
          this.completeUploadProcess.emit(true);
        } else {
          // for update no need to call the document intelligence api
          if (uploadedGuid && uploadedPartGuid) {
            this.completeUploadProcess.emit(false);
          } else {
            this.completeUploadProcess.emit(true);
          }
        }
      }
    }
  }


  /**
   * delete uploaded image
   */
  deleteImage(): void {
    this.monitorService.logEvent('deleteImage', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);
    this.commonService.openDeleteDialog('image')
      .afterClosed().subscribe((data) => {
        if (data) {
          this.delete();
        }
      });
  }

  /**
   * trigger upload
   */
  uploadFile(option?: string): void {
    this.monitorService.logEvent('uploadFile', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);
    this.chqUpload.triggerUpload(option);
  }

  /**
   * update current Page
   */
  setCurrentStep(currentStep: UploadStepModel): void {
    this.currentStepIndex = currentStep?.index;
    this.currentStep = currentStep;
    if (currentStep?.stepName.indexOf('Additional') !== -1) {
      this.skipButtonModel = { ...this.skipButtonModel, label: 'Skip and Review' }
    } else {
      this.skipButtonModel = { ...this.skipButtonModel, label: 'Skip' }
    }
    this.cardSelection.emit(this.currentStep);
  }

  /**
   * is camera flag enabled
   */
  get isCameraEnabled(): boolean {
    //return this.configurationService.getCameraFlags()?.Camera_Internal?.toLowerCase() === 'true' && 
    return this.inspection === 'photos' && this.displayOutline;
  }

  /**
   * skip
   */
  skipUpload(): void {
    this.monitorService.logEvent('skipUpload', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);
    this.stepSkipped.emit();
    if (this.currentStepIndex === (this.imageStatusSteps.length) || (this.totalAdditionalSteps === 0 &&
      this.currentStepIndex === (this.imageStatusSteps.length - 1)) || this.currentStep.isAdditionalDocumentFromReviewPageOnly
      || this.fromReviewPage) {
      if (this.inspection === 'photos' && (this.imageStatusSteps.filter(q => (q.isUploadSuccess !== true && !q.image) && q.mandatory)?.length !== 0)) {
        this.commonService.showToast(0, `${this.imageStatusSteps.filter(q => q.mandatory)?.length === 8 ? 'All 8' : this.imageStatusSteps.filter(q => q.mandatory)?.length === 8} sides images are mandatory and cannot skip to next section.`);
        return;
      }
      this.isReviewSection = true;
      this.chqUpload.closeUploader();
      this.fromReviewPage = false;
    } else {
      if (!this.currentStep.isUploadSuccess) {
        this.currentStep.uploadStatus = 'skipped';
      }
      this.currentStepIndex++;
      const selectedStep = this.imageStatusSteps[this.currentStepIndex];
      this.currentStep = selectedStep;
      this.cardSelection.emit(this.currentStep);
    }

  }


  /**
   * goBack to selection
   */
  goBackToSelection(): void {
    const haveUrl = this.imageStatusSteps.filter((step: UploadStepModel) => {
      return step.url || step.image;
    }).length;
    if (this.isReviewSection && haveUrl > 0) {
      this.isReviewSection = false;
      if (this.isMassUpload) {
        this.hideHeader = true;
      }

      return;
    }
    this.hideHeader = false;
    this.backToUpload.emit();
  }

  /**
   * showReview
   */
  showReview(): void {
    this.isReviewSection = true;
    this.hideHeader = false;
  }

  /**
   * Cancel booking
   */
  cancelBooking(): void {
    this.commonService.openDeleteDialog()
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.calendarService.deleteRepair(this.footerOptions.repairId).subscribe({
            next: () => {
              this.commonService.hideLoading();
              this.router.navigate([ '/repair/booking-journal' ]);
            }
          });
        }
      });
  }

  /**
   * dragover
   */
  @HostListener('dragover', [ '$event' ])
  dragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * dragover
   */
  @HostListener('dragleave', [ '$event' ])
  public dragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * drop
   */
  @HostListener('drop', [ '$event' ])
  public drop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.isReviewSection) return;
    this.chqUpload.handleUpload(event);
  }

  /**
   * ngOnInit hook
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);

    this.currentStep = this.imageStatusSteps[this.currentStepIndex];
    const additionalDocument = this.imageStatusSteps.find(x => x.stepName.includes('Additional Document') && !x.uploadedPartGuid);
    if (additionalDocument) {
      additionalDocument.originalName = additionalDocument.stepName;
      additionalDocument.stepName = additionalDocument.parentName || additionalDocument.stepName;
      additionalDocument.placeHolderUrl = '/assets/icons/additional-image.svg';
    }

    this.commonService.claimStatus.subscribe((claimStatus) => {
      if (claimStatus) {
        this.footerOptions = { ...this.footerOptions, currentStatus: claimStatus }
      }
    });

    this.backButtonModel = { ...this.backButtonModel, onclick: this.goBackToSelection.bind(this) };

    for (let i = 0; i < this.imageStatusSteps.length; i++) {
      this.imageLoading[this.imageStatusSteps[i]['stepName']] = false;
    }
    this.currentNonReviewSteps = this.imageStatusSteps.filter((step: UploadStepModel) => {
      return step.isAdditionalDocumentFromReviewPageOnly !== true;
    }).length;
    this.totalAdditionalSteps = this.imageStatusSteps.length - this.currentNonReviewSteps;
    this.cardSelection.emit(this.currentStep);

    this.commonService.updatePage('/quote/case/0', this.inspection ? this.inspection : 'document');

    const haveUrl = this.imageStatusSteps.filter((step: UploadStepModel) => {
      return step.url;
    }).length;

    if (haveUrl > 0 || (this.header?.isClaimModule && this.inspection != 'photos')) {
      this.isReviewSection = true;
      this.hideBack = true;
    }

    this.skipButtonModel = {
      label: 'Skip',
      type: 'outline',
      onclick: this.skipUpload.bind(this)
    }

    this.uploadButtonModel = {
      label: 'Upload',
      type: 'primary',
      icon: 'upload'
    }

    if (this.header?.isRepairModule) {
      this.uploadButtonModel = { ...this.uploadButtonModel, icon: '', type: 'outline' };
    }

    this.retakeButton = {
      label: 'Retake',
      type: 'primary',
      icon: 'renew'
    }

    this.deleteButton = {
      label: 'Delete',
      type: 'red',
      icon: 'delete',
      onclick: this.deleteImage.bind(this)
    }

    this.skipAllButtonModel = {
      label: 'Skip All',
      type: 'outline',
      onclick: this.skipAllUpload.bind(this)
    }
  }

  /**
   * skip all
   */
  skipAllUpload(): void {
    this.monitorService.logEvent('skip all Upload', [ 'ChqImagesUploaderComponent', 'addenda-quote' ]);

    this.isReviewSection = true;
    this.fromReviewPage = false;
  }

  /**
   * check for mandatory steps
   */
  getNonMandatory(): boolean {
    if (this.inspection === 'photos') {
      return false;
    } else {
      const mandatoryDoc = this.imageStatusSteps.find(x => x.mandatory);
      if (mandatoryDoc) {
        return false;
      } else {
        return true;
      }
    }
  }
}
