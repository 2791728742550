<div class="overlay"
     [ngClass]="{ 'lock-screen':getOrientationLock ,'and': platform.ANDROID , 'right-left':procedureStep?.toLowerCase()?.includes('right side') || procedureStep?.toLowerCase()?.includes('left side')}"><img #imgElement
         [src]="overlayShadow"
         *ngIf="overlayShadow"
         alt="Vehicle Outline">
    <div class="span-con">
        <span *ngIf="procedureStep">{{procedureStep}}</span>
        <span class="tilt-text">{{'tilt_portrait' | translate}}</span>
    </div>

</div>