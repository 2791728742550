import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root' })export class CameraService {
  private stream: MediaStream | null = null;

  /**
   * 
   * @param videoElement 
   * initCamera
   */
  async initCamera(videoElement: HTMLVideoElement, useBackCamera?:boolean): Promise<void> {
    this.stream = await navigator.mediaDevices.getUserMedia({ video: {
      width: { ideal: 2000 },
      // facingMode: useBackCamera? 'environment': '', 
      facingMode: 'environment', //Forcing back camera can be changed later
      height: { ideal: 2000 } } });
    videoElement.srcObject = this.stream;
  }

  /**
   * get camera height widgh
   */
  getCameraHeightWidth():any{
    return {
      height: this.stream?.getVideoTracks()[0].getSettings().height || 0,
      width: this.stream?.getVideoTracks()[0].getSettings().width || 0
    }
  }

  /**
   * stop camera
   */
  stopCamera(): void {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }
  
}