import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { BehaviorSubject, catchError, forkJoin, of } from 'rxjs';
import { FNOL_NEXT_LABEL, QUOTE_NEXT_LABEL, QUOTE_ROUTE } from 'src/app/config/configuration/configuration.constant';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { FooterModel, UploadStepModel } from 'src/app/model/chq-upload-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { documentPartMapper } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CaseService } from 'src/app/services/case/case.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';
import { ChqImagesUploaderComponent } from 'src/app/widgets/chq-images-uploader/chq-images-uploader.component';

const newDocumentHeader: ChqWidgetsHeaderModel = {
  title: 'add_documents',
  step: 3,
  description: 'add_documents_msg',
  stepName: 'step_three',
};

@Component({
  selector: 'new-documents',
  templateUrl: './new-documents.component.html',
  styleUrls: [ './new-documents.component.scss' ]
})
export class NewDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() documentHeader: ChqWidgetsHeaderModel = newDocumentHeader;
  @Input() currentDomainId: number;
  @Input() currentObjectId: number;
  @Input() isReviewSection: boolean;
  @Input() filteredSteps: string[] = [];
  @Input() sendRequest: number;
  @Input() submitSection: string;
  @Input() quoteAssessmentData: any;
  // @Input() status: string;

  @Output() documentUpload: EventEmitter<any> = new EventEmitter();
  @Output() stepLoad: EventEmitter<any> = new EventEmitter();

  public imageStatusSteps: UploadStepModel[] = [];
  public allowExtension = [ 'jpg', 'jpeg', 'png' ];
  @ViewChild('imageUploader') imageUploader: ChqImagesUploaderComponent;
  public skipButtonModel: ChqWidgetsButtonModel = {
    label: 'Skip',
    type: 'primary'
  }

  public footer: FooterModel = {
    text: 'Next add photos',
    icon: 'review',
    route: 'quote/case/photos',
    currentStatus: '',
    status: 'UploadDocuments',
    caseId: ''
  }

  public documentSizeLimit = 5242880;
  public mediaTemplate: any;
  public mediaTemplateId: number;
  public collectionGuid: string;
  public domainId: number;
  public noDocumentData = false;
  private repairId: string = '';
  private currentRepairStatus = '';
  public objectId: number;
  private inspectionId: number;
  private vehicleId: number;
  public mediaPartTemplates: any[];
  public currentObservable: (currentStep: UploadStepModel) => void;
  public currentUploadingStep: UploadStepModel;
  public showNoAccess: boolean = false;
  private currentOrgId: number;
  private companyOrgType = ''

  private countryId: number;

  statusUpdated: boolean = false;
  fromRepairView: boolean = false;
  isExternal: boolean = false;

  documentFraudStatus: string = '';

  /**
   * constructor
   * @param fb ChangeDetectorRef
   */
  constructor(private cd: ChangeDetectorRef, private commonService: CommonService, private route: ActivatedRoute,
    private caseService: CaseService,
    private monitorService: MonitorService,
    private bookingService: BookingService,
    private configurationService: ConfigurationService,
    private estimateService: RepairEstimateService,
    private router: Router,
    private ngZone: NgZone,
    private mediaCollectionService: MediaCollectionService,
    private quoteAssessmentService: QuoteManagementService) {
    if (commonService.pageName.closed) {
      commonService.pageName = new BehaviorSubject<string>(null);
    }
    commonService.pageName.next('document');

    if (this.commonService.accessRight.closed) {
      this.commonService.accessRight = new BehaviorSubject<boolean>(true);
    }

    this.commonService.documentFraudStatus.subscribe({
      next: (response) => {
        if (response) {
          this.documentFraudStatus = response;
        }
      }
    });
  }

  /**
   * ngAfterViewInit hook
   * @param fb FormBuilder
   */
  ngAfterViewInit(): void {
    this.commonService.updatePage('/quote/case/0', 'document');
    this.cd.detectChanges();
    this.monitorService.logEvent('ngAfterViewInit', [ 'ChqNewDocumentsComponent', 'addenda-quote' ]);
  }

  /**
   * check route params
   */
  checkRouteParams(): void {
    this.route.queryParams?.subscribe((params) => {
      const codeParam = params['fromPersonal']
      if (codeParam === 'true' || codeParam === true) {
        this.imageStatusSteps = this.imageStatusSteps.filter((step: UploadStepModel) => {
          return step.stepName.indexOf('Vehicle Registration') != -1;
        });
      }
    });
  }

  /**
   * set current Observable
   */
  setObservable($event: UploadStepModel): void {
    if ($event.uploadedGuid && $event.uploadedPartGuid) {
      this.currentObservable = this.updateFile.bind(this);
    } else {
      this.currentObservable = this.uploadFile.bind(this);
    }
  }

  /**
   * media template by id
   */
  getMediaTemplateByObject(): void {
    this.mediaPartTemplates = this.commonService.getAllChild(this.mediaTemplate?.mediaTemplates, 'mediaPartTemplates', 'mediaTemplateGuid',
      { 'parentProp': 'name', 'childProp': 'parentName' });
    for (let i = 0; i < this.mediaPartTemplates.length; i++) {
      const mediaPart = this.mediaPartTemplates[i];
      const documentStep = documentPartMapper(mediaPart, i);
      const isAdditionalImage = documentStep.stepName.toLowerCase().indexOf('additional document') != -1;
      const isAdditionalDocumentFromReviewPageOnly = isAdditionalImage && !documentStep.url;
      this.imageStatusSteps.push({
        ...documentStep, mandatory: mediaPart.mandatory, index: i,
        isAdditionalDocumentFromReviewPageOnly,
        contentType: mediaPart.contentType,
        fileName: mediaPart.fileName,
        parentName: mediaPart.parentName,
        isFront: mediaPart.frontSide,
        isOcrEnabled: mediaPart.ocrRelevant,
        updatedDate: mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.updatedDate : '',
        uploadedDate: mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.createdDate : '',
        domainId: this.domainId, objectId: this.objectId,
        sizeLimit: mediaPart.sizeLimit, allowExtension: mediaPart.allowExtension,
        uploadedPartGuid: mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.mediaPartGuid : '',
        uploadedGuid: mediaPart.mediaGuid != '00000000-0000-0000-0000-000000000000' ? mediaPart.mediaGuid : '',
        partTemplateGuid: mediaPart.mediaPartTemplateGuid, templateGuid: mediaPart['mediaTemplateGuid']
      });
      if (this.companyOrgType !== 'Insurance Company') {
        this.imageStatusSteps = this.imageStatusSteps.filter(el => el.stepName !== 'Claim Slip')
      }
      this.checkRouteParams();
    }
    if (this.isReviewSection) {
      setTimeout(() => {
        this.imageUploader.goToReview();
        this.stepLoad.emit(this.imageStatusSteps);
      });
    }
  }

  /**
   * update repair status
   */
  updateRepairStatus(repairId: string): void {
    if (!this.statusUpdated) {
      this.statusUpdated = true;
      this.bookingService.updateRepairStatus('UploadDocuments', repairId, '').subscribe({
        next: () => {
          this.currentRepairStatus = 'UploadDocuments';
          this.footer.currentStatus = this.currentRepairStatus;
          this.commonService.showLoading();
          this.bookingService.getRepairDetailByGuid(this.repairId).subscribe({
            next: async (result: any) => {
              if (result?.data) {
                this.commonService.repairDetails.next(result.data);
                this.commonService.bookingId.next(result.data.repairNumber);
                this.commonService.hideLoading();
              }
            },
            error: (err: any) => {
              this.monitorService.logException(err, SeverityLevel.Error);
              this.commonService.hideLoading();
            }
          });
        }
      });
    }
  }

  /**
   * upload file
   */
  uploadFile(currentStep: UploadStepModel): void {

    const obj = {
      mediaCollectionId: this.mediaTemplate.collectionGuid,
      domainId: this.domainId,
      objectId: this.objectId,
      mediaTemplateId: currentStep.templateGuid,
      mediaPartTemplateId: currentStep.partTemplateGuid
    }
    this.monitorService.logEvent('uploadFile', [ 'ChqNewDocumentsComponent', 'addenda-quote', {
      obj
    } ]);
    currentStep.uploadInProgress = true;
    currentStep.isSkipped = false;
    currentStep.isUploadFailed = false;
    currentStep.isUploadSuccess = false;
    const partTemplateGuid = currentStep.partTemplateGuid;
    const messageForUploading = `Your document is currently uploading won’t be long and we’ll
    notify you when its uploaded, you can continue to take your documents.`;
    this.commonService.showNotification(messageForUploading);
    let prop = 'uploadDocument';
    if (this.currentObjectId && currentStep.isOcrEnabled) {
      prop = 'uploadDocumentOCR';
    }
    this.mediaCollectionService[prop](currentStep.imageFile, obj).subscribe({
      next: (res: any): void => {
        const responseData = res?.data;
        if (prop === 'uploadDocumentOCR') {
          this.documentUpload.emit({ 'tags': res.ocrTags, 'step': currentStep.stepName });
          currentStep.uploadInProgress = false;
          currentStep.isSkipped = false;
          currentStep.isUploadSuccess = true;
          currentStep.isUploadFailed = false;
          currentStep.uploadStatus = 'success';
          currentStep.uploadedGuid = res?.mediaGuid;
          currentStep.uploadedPartGuid = res?.mediaPartGuid;
          this.mapMediaTemplateReload(partTemplateGuid, res);
          return;
        }

        this.imageStatusSteps.forEach((el) => {
          if (el.partTemplateGuid === currentStep.partTemplateGuid) {
            el.url = responseData?.blobUrl
          }
        })
        const mediaPartTemplate = this.mediaPartTemplates?.find((x: any) => x.mediaPartTemplateGuid == responseData?.mediaPartTemplateId);
        if (mediaPartTemplate) {
          mediaPartTemplate.mediaGuid = responseData.mediaGuid;
          mediaPartTemplate.mediaPartGuid = responseData.mediaPartGuid;
          if (location.href.includes('repair') && this.currentRepairStatus?.toLowerCase() === 'draft') {
            this.updateRepairStatus(this.repairId);
          }
          if (location.href.includes('quote') && this.footer.currentStatus?.toLowerCase() === 'vehicledetails') {
            this.updateStatus();
          }
          this.imageUploader.updateUploadStatus('success', currentStep, null, responseData);
        }
      },
      error: (err: any): void => {
        if (err.status != 401) {
          try {
            this.imageUploader.updateUploadStatus('failed', currentStep, err);
          } catch (e) {
            this.imageUploader.updateUploadStatus('failed', currentStep);
          }
        }
        this.monitorService.logException(err, SeverityLevel.Error);
      },
      complete: (): void => this.commonService.hideLoading(),
    });
  }

  /**
   * update existing document
   */
  updateFile(currentStep: UploadStepModel): void {
    let obj: any = {
      objectId: this.objectId,
      mediaGuid: currentStep.uploadedGuid,
      mediaPartGuid: currentStep.uploadedPartGuid
    }



    this.monitorService.logEvent('updateFile', [ 'ChqNewDocumentsComponent', 'addenda-quote', {
      obj
    } ]);

    currentStep.uploadInProgress = true;
    currentStep.isSkipped = false;
    currentStep.isUploadFailed = false;
    currentStep.isUploadSuccess = false;
    let prop = 'updateDocument';
    if (this.currentObjectId && currentStep.isOcrEnabled) {
      prop = 'uploadDocumentOCR';
      obj = {
        mediaCollectionId: this.mediaTemplate.collectionGuid,
        domainId: this.domainId,
        objectId: this.objectId,
        mediaTemplateId: currentStep.templateGuid,
        mediaPartTemplateId: currentStep.partTemplateGuid
      }
    }
    const mediaUploadIndex = this.imageStatusSteps.findIndex(value => value.templateGuid === currentStep.templateGuid && value.partTemplateGuid === currentStep.partTemplateGuid);

    this.mediaCollectionService[prop](currentStep.imageFile, obj).subscribe({
      next: (res: any): void => {
        if (prop === 'uploadDocumentOCR') {
          this.imageStatusSteps[mediaUploadIndex].uploadInProgress = false;
          this.imageStatusSteps[mediaUploadIndex].isSkipped = false;
          this.imageStatusSteps[mediaUploadIndex].isUploadFailed = false;
          this.imageStatusSteps[mediaUploadIndex].isUploadSuccess = true;
          setTimeout(() => {
            this.documentUpload.emit({ 'tags': res.ocrTags, 'step': currentStep.stepName });
          }, 100);
          return;
        }
        this.imageUploader.updateUploadStatus('success', currentStep, null, res);
      },
      error: (err: any): void => {
        if (err.status != 401) {
          try {
            this.imageUploader.updateUploadStatus('failed', currentStep, err);
          } catch (e) {
            this.imageUploader.updateUploadStatus('failed', currentStep);
          }
        }
        this.monitorService.logException(err, SeverityLevel.Error);
      },
      complete: (): void => this.commonService.hideLoading(),
    });
  }

  /**
   * update status
   */
  updateStatus(): void {
    if (!this.statusUpdated) {
      this.statusUpdated = true;
      this.monitorService.logEvent('updateStatus', [ 'ChqNewDocumentsComponent', 'addenda-quote', {
        caseId: this.footer.caseId
      } ]);
      this.caseService.updateCaseStatus(this.footer.caseId, 'UploadDocuments').subscribe({
        next: () => {
          this.footer = { ...this.footer, status: '' };
        }
      })
    }
  }



  /**
   * map media template Data
   * @param mediaData
   */
  mapMediaTemplate(mediaData: any): void {
    if (this.mediaTemplate) {
      try {
        for (const media of this.mediaTemplate.mediaTemplates) {
          const mediaDataTemplate = mediaData.find((x: any) => x.mediaTemplateGuid == media.mediaTemplateGuid);
          if (mediaDataTemplate) {
            for (const element of media.mediaPartTemplates) {
              const mediaPart = element;
              const mediaDataPart = mediaDataTemplate.mediaParts.find((x: any) => x.mediaPartTemplateGuid == mediaPart.mediaPartTemplateGuid);
              mediaPart.fileName = mediaDataPart.fileName;
              mediaPart.contentType = mediaDataPart.contentType;
              mediaPart.mediaGuid = mediaDataTemplate.mediaGuid;
              mediaPart.mediaPartGuid = mediaDataPart.mediaPartGuid;
              mediaPart.createdDate = mediaDataPart.createdDate;
              mediaPart.updatedDate = mediaDataPart.updatedDate;
              mediaPart.image = mediaDataPart.getURL;
              mediaPart.isUploadSuccess = false;
              if (mediaPart.mediaPartGuid && mediaPart.mediaPartGuid != '00000000-0000-0000-0000-000000000000') {
                mediaPart.isUploadSuccess = true;
                if (mediaDataPart.ocrTagDetail) {
                  this.documentUpload.emit({
                    'tags': mediaDataPart.ocrTagDetail?.ocrTags?.map((tg) => {
                      return {
                        key: tg.tagName,
                        value: tg.tagValue
                      }
                    }), 'step': mediaPart.name
                  });
                }
              }
            }
          }
        }
        this.mediaTemplate = { ...this.mediaTemplate };

      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * map media template Data
   * @param mediaData
   */
  mapMediaTemplateReload(partTemplateGuid: string, resp: any): void {
    try {
      const mediaPartTemplate = this.mediaPartTemplates?.find((x: any) => x.mediaPartTemplateGuid == partTemplateGuid);
      if (mediaPartTemplate) {
        mediaPartTemplate.mediaGuid = resp.mediaGuid;
        mediaPartTemplate.mediaPartGuid = resp.mediaPartGuid;
        if (location.href.includes('repair') && this.currentRepairStatus?.toLowerCase() === 'draft') {
          this.updateRepairStatus(this.repairId);
        }
        if (location.href.includes('quote') && this.footer.currentStatus?.toLowerCase() === 'vehicledetails') {
          this.updateStatus();
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  /**
   * get next step
   */
  getNextStep(currentModule: string, domainId: number): string {
    const stepData = this.configurationService.getStepByDomainId(domainId)?.sort((a, b) => a.SortOrder - b.SortOrder);
    const documentConfigIndex = stepData.findIndex((step) => { return step.ItemKey === currentModule });
    if (documentConfigIndex !== -1) {
      if (documentConfigIndex === (stepData?.length - 1)) {
        return '';
      }
      return stepData[documentConfigIndex + 1].ItemKey;
    }
    return '';
  }

  /**
   * map media template Data
   * @param mediaData
   */
  mapMediaTemplateRepairerView(mediaData: any): void {
    if (mediaData?.length > 0) {
      try {
        const mediaDataWithMedias = mediaData.filter(q => q.domainId != 0);
        for (let i = 0; i < mediaDataWithMedias.length; i++) {
          const mediaParts = mediaDataWithMedias[i].mediaParts;
          if (mediaParts?.length > 0) {
            for (const element of mediaParts) {
              const mediaPart = element;
              if (!mediaPart.getURL) {
                continue;
              }

              this.imageStatusSteps.push({
                stepName: mediaPart.name,
                mandatory: false,
                placeHolderUrl: '',
                allowExtension: [],
                sizeLimit: 0,
                index: i,
                inspectionItem: null,
                image: null,
                url: mediaPart.getURL,
                isSkipped: false,
                isEmpty: false,
                isUploadFailed: false,
                isUploadSuccess: true,
                uploadInProgress: false,
                templateGuid: '',
                partTemplateGuid: '',
                uploadStatus: 'success',
                analysisStatus: null,
                contentType: mediaPart.contentType,
              })
            }
          }
        }

      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * go to gtEstimate
   */
  redirectToGtEstimate(): void {
    this.commonService.showLoading();
    if (this.footer.currentStatus?.toLowerCase() === 'uploaddocuments' || this.footer.currentStatus?.toLowerCase() === 'uploadphotos') {
      this.estimateService.createEstimate(this.footer.caseId, this.inspectionId, this.vehicleId, this.domainId).subscribe({
        next: (resp: any) => {
          if (resp) {
            if (resp?.data?.url) {
              location.href = resp.data.url;
              sessionStorage.setItem('update', '1');
            }
          }
        },
        complete: () => {
          this.commonService.hideLoading();
        }
      });
    } else {
      this.router.navigate([ `/quote/case/${this.footer.caseId}/estimate` ]);
    }
  }

  /**
   * navigates to next page
   */
  handleNavigation(domainId: number): void {
    this.monitorService.logEvent('handleNavigation', [ 'NewDocumentComponent', 'addenda-quote', {
      caseId: this.objectId
    } ]);
    if (domainId === 2100) {
      const stepData = this.configurationService.getStepByDomainId(2100)?.sort((a, b) => a.SortOrder - b.SortOrder);
      const documentConfigIndex = stepData.findIndex((step) => { return step.ItemKey === 'Addenda_Quote_Documents' });
      if (documentConfigIndex) {
        if (documentConfigIndex === (stepData - 1)) {
          return;
        }
        const route = QUOTE_ROUTE[stepData[documentConfigIndex + 1].ItemKey];
        if (route === QUOTE_ROUTE.Addenda_Quote_Repair_Estimate) {
          this.footer = { ...this.footer, finalRedirection: this.redirectToGtEstimate.bind(this) };
        } else {
          this.footer = { ...this.footer, route: `/quote/case/${this.footer.caseId}/${route}` };
        }
      }
    } else if (domainId === 1100) {
      this.footer = {
        ...this.footer, status: '', finalRedirection: (): void => {
          this.documentUpload.emit({ 'value': this.getNextStep('Fnol_Documents', 1100), 'step': 'navigate' });
        }
      };
    } else if (domainId === 4102) {
      this.footer = {
        ...this.footer, status: '', finalRedirection: (): void => {
          this.documentUpload.emit({ 'value': '', 'step': 'navigate' });
        }
      };
    }
    

  }


  /**
   * ngOnInit hook
   */
  ngOnInit(): void {
    this.commonService.showLoading();
    let id = null;
    if(this.currentDomainId !== 4102) {
      id = this.route.parent?.snapshot.paramMap.get('id');
      this.monitorService.logEvent('ngOnInit', [ 'ChqNewDocumentsComponent', 'addenda-quote', {
        caseId: id
      } ]);
      this.footer = { ...this.footer, route: `quote/case/${id}/photos`, caseId: id };
    } else if(this.currentDomainId === 4102) {
      this.footer = { ...this.footer, status: '' };
      // if(this.status) {
      //   this.footer = { ...this.footer, currentStatus: this.status }
      // }
    }
    if (this.commonService.accessRight.closed) {
      this.commonService.accessRight = new BehaviorSubject<boolean>(true);
    }
    this.commonService.accessRight.subscribe({
      next: (res: boolean) => {
        if (!res) {
          this.showNoAccess = true;
        }
      }
    });
    const promiseApi: any = {
      mediaTemplate: this.mediaCollectionService.getMediaTemplate(this.currentDomainId)
    }
    let isRepair = false;

    if (location.href.includes('repair')) {
      promiseApi.bookingDetail = this.bookingService.getRepairDetailByGuid(id);
      isRepair = true;
    } else if (!this.currentObjectId) {
      this.commonService.userProfileData.subscribe((res) => {
        if (res && res.data) {
          const { organizationDetail } = res.data;
          this.currentOrgId = organizationDetail.id;
          const permissionList = res?.data.userPermission.automotiveServices[0].permissions;
          this.isExternal = this.commonService.hasPermission('case.external', permissionList);
        }
      });
      if(id) {
        promiseApi.caseDetail = this.caseService.getCaseDetail(id);
      }
    }

    if (this.currentObjectId) {
      this.objectId = this.currentObjectId;
    }

    const result = forkJoin(promiseApi).pipe(
      catchError(error => of(error))
    )
    result.subscribe((dict: any) => {
      if (dict) {
        const mediaTemplate = dict.mediaTemplate;
        if (mediaTemplate) {
          const mediaTemplateSelected = mediaTemplate.data;
          if (dict.caseDetail) {
            const { caseNumber, id, status, inspections, repairGuid, organizationDetail, vehicleId, domainID } = dict.caseDetail.data;
            if (this.currentOrgId != organizationDetail?.organizationId && !this.isExternal) {
              this.commonService.isViewOnly = true;
            } else {
              this.commonService.isViewOnly = false;
            }

            if (inspections.length > 0) {
              this.inspectionId = inspections[0].inspectionId;
              this.commonService.inspectionId.next(inspections[0].inspectionId);
              this.vehicleId = vehicleId;
            }
            this.commonService.caseStatus.next(status);
            this.commonService.caseDetail.next(dict.caseDetail.data);
            if (status.toLowerCase() === 'vehicledetails') {
              //   this.updateStatus();
            } else {
              this.footer = { ...this.footer, status: '' };
            }
            this.footer.currentStatus = status;
            this.documentHeader.caseNumber = caseNumber;
            this.handleNavigation(2100);
            this.objectId = id;
            this.footer.objectId = id;
            this.footer.domainId = domainID
            if (repairGuid) {
              this.footer.repairId = repairGuid;
              this.footer.from = 'Quote';
            }
            const nextLabel = this.getNextStep('Addenda_Quote_Documents', 2100);
            this.footer = { ...this.footer, text: nextLabel ? QUOTE_NEXT_LABEL[nextLabel] : 'next_add_photos', icon: '' };
          }
          if (dict.bookingDetail) {
            const { id, status, repairGuid, quoteId, repairNumber, isRepairCreatedFromCase, bookings, customer, domainId } = dict.bookingDetail.data;
            this.companyOrgType = customer.companyOrgType;
            this.objectId = id;
            this.footer.objectId = id;
            this.footer.domainId = domainId
            this.repairId = repairGuid;

            //adding
            if (quoteId) {
              this.footer.caseId = quoteId;
              this.footer.from = 'Repair';
            }

            this.footer.isRepairCreatedFromCase = isRepairCreatedFromCase;
            this.footer.repairId = repairGuid;

            this.footer.currentStatus = status;

            this.commonService.repairStatus.next(status);
            //

            this.currentRepairStatus = status;
            // Send booking data in observable
            this.commonService.repairDetails.next(dict.bookingDetail.data);
            this.commonService.bookingId.next(repairNumber);
          }

          if(this.currentDomainId === 4102) {
            this.footer.objectId = this.currentObjectId;
            this.footer.domainId = this.currentDomainId
          }
          if (mediaTemplateSelected.length > 0) {
            const { id, collectionGuid, countryID } = mediaTemplateSelected[0];
            this.mediaTemplateId = id;
            this.domainId = 2100;
            if (isRepair) {
              this.domainId = 3100;
            }

            this.documentUpload.emit({ 'value': countryID, 'step': 'country' });
            this.documentUpload.emit({ 'value': id, 'step': 'documentCollectionId' });
            if (this.currentDomainId) {
              this.domainId = this.currentDomainId;
              this.handleNavigation(this.domainId);
              const nextLabel = this.getNextStep('Fnol_Documents', this.currentDomainId);
              this.footer = { ...this.footer, text: nextLabel ? FNOL_NEXT_LABEL[nextLabel] : 'add_photos' };
            }

            this.collectionGuid = collectionGuid;
            const detailResult = forkJoin({
              mediaTemplateDetail: this.mediaCollectionService.getMediaTemplateById(collectionGuid),
              mediaDetail: this.mediaCollectionService.getMediaDocumentData(id, this.currentObjectId || this.objectId, this.domainId)
            }).pipe(
              catchError(error => of(error))
            )
            detailResult.subscribe((detailDict: any) => {
              if (detailDict) {
                const { mediaTemplateDetail, mediaDetail } = detailDict;
                this.mediaTemplate = mediaTemplateDetail.data;
                this.mediaTemplate.mediaTemplates = mediaTemplateDetail.data.mediaTemplates.sort((media: any, mediab: any) => {
                  return media.orderNumber - mediab.orderNumber;
                })

                // Only for repairer view, since the template ids are different
                if (mediaDetail.data) {
                  if (this.router.url.toString().indexOf('/repairer-view/') != -1) {
                    this.fromRepairView = true;
                    this.mapMediaTemplateRepairerView(mediaDetail.data.medias);

                    if (this.imageStatusSteps?.length == 0) {
                      this.noDocumentData = true;
                    }
                    this.commonService.hideLoading();
                    return;
                  }

                  this.mapMediaTemplate(mediaDetail.data.medias);
                }
                this.getMediaTemplateByObject();

                // document fraud status
                if ((!this.documentFraudStatus || this.documentFraudStatus?.toLowerCase() == 'in progress')
                  && this.commonService.documentFraudCall) { //document fraud status is null check 
                  const uploadedImages = this.imageStatusSteps.filter(q=>q.url != null && q.url != '') || [];
                  if (uploadedImages.length > 0) {
                    this.quoteAssessmentService.updateFraudDetectionDocument(this.quoteAssessmentData?.caseGuid).subscribe();
                  }
                }

                //
              }
              this.commonService.hideLoading();
            }, (err) => {
              this.commonService.hideLoading();
              this.monitorService.logException(err, SeverityLevel.Error);
            });

          } else {
            this.commonService.hideLoading();
          }
        } else {
          this.noDocumentData = true;
          this.commonService.hideLoading();
        }

      } else {
        this.noDocumentData = true;
        this.commonService.hideLoading();
      }
    });
    const container: any = document.querySelector('.addenda-container');
    if (container) {
      container.scrollTop = 0;
    }

  }

  /**
   * handled if step is skipped
   */
  stepSkipped(): void {
    if (location.href.includes('repair') && this.currentRepairStatus?.toLowerCase() === 'draft') {
      this.updateRepairStatus(this.repairId);
    }
    if (location.href.includes('quote') && this.footer.currentStatus?.toLowerCase() === 'vehicledetails') {
      this.updateStatus();
    }
  }

  /**
   * update fraud detection after document upload 
   * @param $event 
   */
  completeUploadProcess($event): void {
    if ($event && this.documentFraudStatus && this.commonService.documentFraudCall) {
      this.updateDocumentFraud();
    }
  }

  /**
   * call fraud detection
   */
  updateDocumentFraud(): void {
    this.quoteAssessmentService.updateFraudDetectionDocument(this.footer?.quoteAssessmentId).subscribe({
      next: () => {
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
    
  }

  /**
   * on destroy
  */
  ngOnDestroy(): void {
    this.monitorService.logEvent('ngOnDestroy', [ 'ChqNewDocumentComponent', 'addenda-quote' ]);
    this.commonService.accessRight.unsubscribe();

    if (location.href.includes('quote')) {
      this.commonService.isViewOnly = false;
    }
  }
}