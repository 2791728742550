<div class="submit-request-missing-info">
    <div class="header">
        <div class="title-container">
            <div class="heading">
                <!-- <img src="assets/img/liva-logo.png" class="logo"/> -->
                <span class="title">{{ 'requirement_list' | translate}}</span>
                <!-- <span class="sub-title">{{ 'missing_details' | translate}}</span> -->
            </div>
        </div>

    </div>
    <div class="submit-missing-info"
         *ngIf="!isSubmitted else completed">
        <div class="sub-heading"
             *ngIf="infoReRequested">{{'missing_details_sub_heading_re_request' | translate}}</div>
        <div class="sub-heading"
             *ngIf="!infoReRequested">{{'missing_details_sub_heading' | translate}}</div>
        <div class="content first-content"
             *ngIf="controls?.length > 0">
            <div class="title">
                <span>{{'personal_details' | translate}}</span>
            </div>
            <dynamic-form [formControls]="controls"
                          class="form-container"
                          [form]="quoteCustomerFormGroup"></dynamic-form>

        </div>

        <div class="content first-content"
             *ngIf="vehicleCtrlList?.length > 0">
            <div class="title">
                <span>{{'vehicle_detail' | translate}}</span>
            </div>
            <dynamic-form [formControls]="vehicleCtrlList"
                          class="form-container"
                          [form]="quoteVehicleFormGroup"></dynamic-form>

        </div>

        <div *ngIf="inspectionId"
             class="w-100">
            <div class="title">
                <span>{{'vehicle_images' | translate}}</span>
            </div>
            <new-photos [currentInspectionID]="inspectionId"
                        [currentDomainId]="domainId"
                        [fnolGUID]="'noNeed'"
                        [hideEstimate]="true"
                        [photosHeader]="photosHeader"
                        [hideBulk]="true"
                        [hideVideo]="true"
                        [hideAi]="true"
                        #newPhoto
                        [displayOutline]="true"
                        (stepLoad)="stepLoad($event, 'photos')"
                        [currentObjectId]="objectId"
                        [quoteAssessmentId]="caseGuid"
                        [isReviewSection]="true"
                        [filteredSteps]="imageFilters"
                        (videoInspectionItemDetails)="videoInspectionItemDetails($event)"
                        [currentVehicle]="{}"
                        submitSection="photos"></new-photos>
        </div>
        <div *ngIf="captureVideo">
            <div class="title">
                <span>{{'video_upload' | translate}}</span>
            </div>
            <video-uploader #videoUploader
                            (fileChanged)="handleFileChange($event)"
                            [uploadStatus]="videoStatus"
                            [videoUrl]="videoUrl"></video-uploader>
        </div>
        <div class="w-100"
             *ngIf="showDocument">
            <div class="title">
                <span>{{'vehicle_documents' | translate}}</span>
            </div>
            <new-documents [currentObjectId]="objectId"
                           [documentHeader]="newDocumentHeader"
                           (documentUpload)="handleOcrTags($event);"
                           [filteredSteps]="documentFilters"
                           [isReviewSection]="true"
                           (stepLoad)="null"
                           #newDocuments
                           [currentDomainId]="domainId"
                           submitSection="document"></new-documents>
        </div>
        <div class="content btn-content">
            <xa-button (click)="submitForm();">{{'submit' | translate}}</xa-button>
        </div>
    </div>
</div>
<ng-template #completed>
    <div class="linear-gradient-effect"></div>
    <div class="completed-container">
        <img src="assets/img/completed_submitted.png" />
        <div class="msg">
            <span>{{'thank_you_msg' | translate}}</span>
            <span>{{'success_message' | translate}}</span>
        </div>
    </div>
</ng-template>