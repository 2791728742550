<div class="video-uploader h-100 flex align-center justify-center">
    <div class="content">
        <div class="step-wrapper" [ngClass]="{'border-gray-dotted': !videoUrl && !selectedFile}">
            <div class="step-container">
                <div class="step-desc">
                    <div class="step-text">
                        <div class="step-title">
                            <label for="">360 Video</label>
                            <mat-icon *ngIf="(uploadStatus.toLowerCase() ==='success' || videoUrl) else unchecked"
                                        svgIcon="checked"
                                        aria-hidden="false"
                                        aria-label="checked icon"></mat-icon>
                            <ng-template #unchecked>
                                <mat-icon svgIcon="unchecked" aria-hidden="false" aria-label="unchecked icon"></mat-icon>
                            </ng-template>
                        </div>
                        <div class="step-title ai-status-text">
                            <span>{{uploadStatus.toLowerCase() === 'uploadinprogress' ? 'Upload in Progress' : (uploadStatus.toLowerCase() === 'isuploadsuccess' ? 'Uploaded': 'Not Uploaded')}}</span>
                        </div>
                    </div>
                </div>
                <div class="step-image">
                    <mat-icon class='video-icon' svgIcon="play" aria-hidden="false" aria-label="play icon" (click)="openVideoPlayer()"></mat-icon>
                    <video *ngIf="videoUrl || localVideoUrl" playsinline class="video-placeholder">
                        <source [src]="videoUrl || localVideoUrl" type="video/mp4">
                    </video>
                    <input hidden #uploader type="file" name="imageData" id="fileDropRef" (change)="handleUpload($event)" accept="video/mp4,video/x-m4v,video/*"/>
                </div>
                <div class="step-action-icon" *ngIf="status !== 'Completed'">
                    <mat-icon svgIcon="edit"
                                class="edit-icon border-light-gray"
                                *ngIf="videoUrl || localVideoUrl"
                                aria-hidden="false"
                                (click)="uploader.click()"
                                aria-label="edit icon"></mat-icon>
                    <xa-button *ngIf="!videoUrl && !localVideoUrl" (click)="uploader.click()" type="primary" class="left">{{'upload_video' | translate }}</xa-button>
                    <mat-icon svgIcon="delete"
                                class="delete-icon border-danger"
                                aria-hidden="false"
                                *ngIf="videoUrl || localVideoUrl"
                                (click)="$event.stopPropagation();delete();"
                                aria-label="unchecked icon"></mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
