import { environment } from 'src/environments/environment'
export const MAX_TASKS_PER_REPAIR = 20

export const claimNumberEditNotAllowedForRepairStatuses = [ 'complete', 'closed', 'Complete', 'Closed', 'ReadytoInvoice', 'readytoinvoice' ];

export const driverEditNotAllowedStatuses = [ 'complete', 'closed', 'Complete', 'Closed', 'invoiced', 'paid' ];

export const repairStartedStatuses = [ 'repairinprogress', 'readytoinvoice', 'invoiced', 'paid', 'complete', 'closed', 'rejected' ];

export const notApprovedStatuses = [ 'draft', 'vehicledetails', 'uploaddocuments', 'uploadphotos', 'repairestimation', 'pendingapproval', 'approved', 'rejected' ];

export const repairEstimatedStatuses = [ 'repairestimation', 'pendingapproval', 'approved', 'repairinprogress', 'readytoinvoice', 'invoiced', 'paid', 'complete', 'closed', 'rejected' ];

export const repairBookingType = [ 'Booking In', 'Booking Out', 'Repair' ];

export const QuoteTemplateKey = 'CaseEstimateApprovalRequest';

export const RepairTemplateKey = 'EstimateApprovalRequest';

export const ApprovalStatusList = [ 'Pending Approval', 'Modified', 'Approved', 'Rejected' ];

export const TechnicianCalendarAllowedStatuses = [ 'modified', 'approved', 'repairinprogress', 'readytoinvoice', 'invoiced', 'paid', 'complete', 'closed' ];

export const UpsaleEditNotAllowedStatuses = [ 'invoiced', 'paid', 'complete', 'closed' ];

export const photosSizeLimit = 5242880;
export const pdfSizeLimit = 5242880 * 2;
export const minimumSizeLimit = 204800;
export const allowExtension = [ 'jpg', 'jpeg', 'png' ];
export const AllowedDocumentType = [ 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf' ];


export const serviceURL = {
  'CaseManagement': environment.caseMgmtUrl,
  'BookingManagement': environment.bookingMgmtUrl,
  'MediaManagement': environment.mediaManagementUrl,
  'Fnol': environment.fnolApiUrl,
  'AutoCore': environment.autoCoreApi,
  'AutoApi': environment.autoApi,
  'Communication': environment.communicationApi,
  'Aggregate': environment.xaAggregationApi
}

export enum serviceURLMapper{
  'autoCore'= 'AutoCore',
  'autoFoundation'= 'AutoApi',
  'bookingMgmt'= 'BookingManagement',
  'caseMgmt' = 'CaseManagement',
  'communication' = 'Communication',
  'fnol' = 'Fnol',
  'aggregation'='Aggregate',
  'mediaMgmt'= 'MediaManagement'
}

export const COMPETENCIES_LIST = [
  {
    'name': 'Body',
    'svg': 'car',
  },
  {
    'name': 'Maintenance',
    'svg': 'house',
  },
  {
    'name': 'Paint',
    'svg': 'paint-nozzle',
  },
  {
    'name': 'Mechanical',
    'svg': 'mechanical',
  },
  {
    'name': 'Diagnosis',
    'svg': 'diagnosis',
  },
  {
    'name': 'Tyres',
    'svg': 'tyres',
  },
  {
    'name': 'Electrical',
    'svg': 'bolt-car',
  },
  {
    'name': 'Others',
    'svg': 'gears',
  },
]

export const JobStatus = {
  NotStarted: 1,
  Inprogress: 2,
  Completed: 3,
  ReadyToStart: 4
}

export const ManualEstimateCalculationType = {
  'CostPerPanel': 2,
  'EstimatedHours': 1
}

export const WORKFLOW_PERSIST_KEYS = {
  'PERSIST_ACTIVE_TAB': 'PERSIST_ACTIVE_TAB'
}

export const EstimateSources = {
  'GTMotive': 'GTMotive',
  'AddendaAIEstimate': 'AddendaAIEstimate',
  'AddendaEstimate': 'AddendaEstimate'
}

export const DamageAnalysisStatus = {
  'Analysed': 'Analysed',
  'AnalysisFailed': 'Analysis Failed',
  'AnalysisInProgress': 'Analysis In Progress'
}
