export interface uploadEventResult {
    image: string | ArrayBuffer,
    fileName: string,
    file: File
}

export interface UploadStepModel {
    'stepName': string,
    'parentName'?:string,
    'mandatory': boolean,
    contentType?:string,
    fileName?:string,
    'isFront'?:boolean,
    'allowExtension': string[],
    'sizeLimit': number,
    'index': number,
    originalName?:string,
    'placeHolderUrl': string,
    'image'?: string | ArrayBuffer,
    'url'?: string,
    'procedureSteps'?:string,
    'isReviewOnly'?: boolean,
    'isAdditionalDocumentFromReviewPageOnly'?: boolean,
    'isMassUpload'?: boolean,
    'uploadStatus': 'inprogress' | 'success' | 'skipped' | 'failed' | 'notUploaded',
    templateGuid: string,
    thumbnailUrl?:string,
    partTemplateGuid: string,
    isOcrEnabled?:boolean,
    uploadedPartGuid?: string,
    domainId?: number,
    objectId?: number,
    uploadedGuid?: string,
    uploadInProgress: boolean,
    imageHeight?: number,
    imageWidth?: number,
    inspectionItem?:any,
    isEmpty?:boolean,
    isSkipped: boolean,
    isUploadFailed: boolean,
    imageFile?: File,
    isUploadSuccess: boolean,
    analysisStatus?:string,
    uploadedDate?:string,
    updatedDate?:string,
    overlayImage?:{
        aspectRatio:number,
        rawAzureBlobUrl:string,
        resolution:number,
        type:number, 
        version: number
    },
    isLocked?: boolean,
    instruction?:string
}

export interface FooterModel {
    text: string;
    icon: string;
    route: string;
    status: string;
    currentStatus: string;
    caseId?: string;
    assignedGarageType?: string;
    repairId?: string;
    claimId?: string;
    from?: string; //quote or repair
    showButton?: boolean;
    hideEdit?: boolean;
    hideNext?: boolean;
    showBack?: boolean;
    showUploadOption?:boolean;
    repairTypes?:any[];
    isRepairModule?:boolean;
    isClaimModule?: boolean;
    isQuoteAssessmentModule?: boolean;
    isRepairCreatedFromCase?: boolean;
    bookingId?: string;
    section?: string;
    hasSurveyorOrgPackagePermission?: boolean,
    handleBack?: (back: { back: boolean }) => void,
    finalRedirection?: () => void,
    objectId?: any,
    domainId?: number,
    hideDownloadButton?: boolean,
    aiEstimationStatus?: string,
    quoteAssessmentId?: string
}

export enum Modules {
    quote = 'Quote',
    repair = 'Repair'
}
