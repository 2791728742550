import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerComponent } from 'src/app/dialogs/video-player/video-player.component';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'video-uploader',
  templateUrl: './video-uploader.component.html',
  styleUrls: [ './video-uploader.component.scss' ]
})
export class VideoUploaderComponent implements OnChanges{
  @ViewChild('uploader') uploader: ElementRef;
  @Input() videoUrl: string = '';
  @Input() uploadStatus: string = 'Not Uploaded';
  @Input() maxFileSize: number = 50000000;
  @Input() status: string;
  @Output() fileChanged: EventEmitter<{ 'file'?: File, 'action'?: string }> = new EventEmitter();
  localVideoUrl: string = '';
  selectedFile: File = null;

  /**
   * constructor
   * @param commonService
   * @param dialog
   */
  constructor(
    private commonService: CommonService,
    private dialog: MatDialog
  ) {

  }

  /**
   * on change event
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['uploadStatus']?.currentValue === 'IsUploadFailed') {
      this.selectedFile = null;
      this.localVideoUrl = '';
      this.uploader.nativeElement.value = null;
    }
  }

  /**
   * delete  video
   */
  delete(): void {
    //todo delete feature
    this.localVideoUrl = '';
    this.videoUrl = '';
    this.fileChanged.emit({ 'file': null, 'action': 'delete' });
  }


  /**
   * handle file upload
   * @param event 
   */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = 'Video size is above the allowed 50MB limit, try again with a smaller video';
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      this.selectedFile = currentFile[0];
      this.localVideoUrl = URL.createObjectURL(this.selectedFile);
      this.fileChanged.emit({ 'file': this.selectedFile, 'action': 'add' });
    } else {
      this.uploader.nativeElement.value = null;
      return;
    }
  }

  /**
   * checks file size
   * @param blob 
   * @returns boolean
   */
  checkFileSize(file: Blob): boolean {
    if (file.size > this.maxFileSize) {
      return false;
    }
    return true;
  }
  
  /**
   * open video player
   */
  openVideoPlayer(): void {
    if(this.videoUrl || this.localVideoUrl) {
      this.dialog.open(VideoPlayerComponent, {
        data: {
          videoUrl: this.localVideoUrl || this.videoUrl
        }, autoFocus: false
      });
    }
  }
}

