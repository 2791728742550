import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { catchError, forkJoin, Observable, Observer, of } from 'rxjs';
import { uploadEventResult, UploadStepModel } from 'src/app/model/chq-upload-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { allowExtension, minimumSizeLimit, photosSizeLimit, pdfSizeLimit } from 'src/app/config/constants/app.constants';
import { CameraComponent } from '../camera/camera.component';

@Component({
  selector: 'chq-upload',
  template: `<xa-camera [skipText]="skipText" [forceOrientation]="true" [displaySkip]="showSkip" [outlineUrl]="outlineUrl" 
  [procedureStep]="currentStep?.instruction" #camera [currentStep]="currentStep?.stepName" (skipped)="this.skipped.emit();" *ngIf="!isMultiple" (imageUploaded)="handleUpload($event)"></xa-camera>
  <input hidden  type="file" #upload (change)="handleUpload($event)" [multiple]="isMultiple" [accept]="acceptValue">`,
})

export class ChqUploadComponent implements OnInit {

  @Input() currentStep: UploadStepModel;
  @Input() isMultiple;
  @Input() isCurrentAdditionalModeMultiple:boolean;
  @Input() currentFileNumber;
  @Input() showSkip:boolean;
  @Input() skipFileSizeValidation:boolean;
  @Input() currentPageName:string;
  @ViewChild('upload') upload: ElementRef;
  @ViewChild('camera') camera: CameraComponent;
  @Output() imageOutput: EventEmitter<uploadEventResult> = new EventEmitter();
  @Output() skipped: EventEmitter<any> = new EventEmitter();
  @Input() maxFileAllowed: number = 20;
  skipText = 'skip';
  acceptValue: string = 'image/png, image/jpg, image/jpeg';
  /**
 * constructor
 */
  constructor(private el: ElementRef, private commonService: CommonService, private monitorService:MonitorService) { }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    if(this.currentPageName !== 'photos') {
      this.acceptValue += ', application/pdf'
    }

    if (this.currentStep?.stepName.indexOf('Additional') !== -1) {
      this.skipText = 'Skip and Review';
    } else {
      this.skipText = 'Skip' ;
    }
  }
  /**
   * checks file extension
   * @param filename
   * @returns boolean
   */
  public getFileExtension(filename: string): string | undefined {
    return filename.split('.').pop();
  }

  /**
   * outline url
   */
  get outlineUrl():string{
    return this.currentStep ? `${this.currentPageName === 'photos' ? (this.currentStep?.overlayImage?.rawAzureBlobUrl || 
      `/assets/icons/outlines/${this.currentStep.stepName?.replace(' ', '-')?.toLowerCase()}.svg`)
      : '/assets/icons/outlines/document.svg'}` : '/assets/img/addenda-white.svg';
  }

  /**
   * hide uploader
   */
  closeUploader():void{
    this.camera.closeCamera();
  }

  /**
   * checks file size
   * @param blob
   * @returns boolean
   */
  checkFileSize(img: Blob): boolean {
    const sizeLimit = img.type === 'application/pdf' ? pdfSizeLimit : photosSizeLimit;
    if (img.size > sizeLimit || (img.size < minimumSizeLimit && this.currentPageName ==='photos')) {
      this.monitorService.logEvent('checkFileSize', [ 'ChqUploadComponent', 'addenda-quote', {
        message: 'invalid file size.'
      } ]);
      return false;
    }
    return true;
  }

  /**
   * checkx filename
   * @param filename
   * @returns
   */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      const fileExtension = this.currentStep ? this.currentStep.allowExtension : allowExtension;
      return fileExtension.includes(extension?.toLowerCase());
    }
    return false;

  }

  /**
   * trigger upload
   */
  triggerUpload(options?: any): void {
    if(this.isMultiple || options !== 'integrated'){
      setTimeout(() => {
        this.upload.nativeElement.click();
      });
    }else{
      this.camera.startCamera();
    }
  }

  /**
   * trigger null
   */
  resetValue(): void {
    setTimeout(() => {
      this.upload.nativeElement.value = null;
    });

  }

  /**
   * file upload observable
   */
  fileReaderObservable(file: any): Observable<any> {
    const sequence = new Observable((observer: Observer<any>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any): void => {
        observer.next({ image: reader.result, fileName: file.name, file: file });
        observer.complete();
      }
    });
    return sequence;
  }

  /**
   * handle file upload
   * @param event
   */
  handleUpload(event: any): void {
    this.monitorService.logEvent('handleUpload', [ 'ChqUploadComponent', 'addenda-quote', {
      event
    } ]);
    const currentFile = event.target.files || event.srcElement.files || event.dataTransfer.files;

    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      this.commonService.showLoading();
      if (this.isMultiple || this.isCurrentAdditionalModeMultiple) {
        if (currentFile.length > this.maxFileAllowed) {
          this.commonService.showToast(0, `Maximum ${this.maxFileAllowed} files are allowed.`);
          this.commonService.hideLoading();
          this.resetValue();
          return;
        }
        if ((currentFile.length + this.currentFileNumber) > this.maxFileAllowed) {
          this.commonService.showToast(0, `Maximum ${this.maxFileAllowed} files are allowed.`);
          this.commonService.hideLoading();
          this.resetValue();
          return;
        }
        const observableList = {};
        let invalidFiles = 0;
        let invalidSize = 0;
        for (let i = 0; i < currentFile.length; i++) {
          if (!this.isValidImageFile(currentFile[i].name)) {
            invalidFiles++;
            continue;
          }

          if (!this.checkFileSize(currentFile[i]) && !this.skipFileSizeValidation) {
            invalidSize++;
            continue;
          }

          observableList[i] = this.fileReaderObservable(currentFile[i]);
        }
        if (invalidSize > 0) {
          let message = 'For an accurate A.I. analysis please upload images which are not smaller than 200 KB and do not exceed 5 MB';
          if(this.currentFileNumber === 9) {
            message = 'Please upload image which are not smaller than 200 KB and do not exceed 5 MB';
          }
          this.commonService.showToast(0, message);

        }

        if (invalidFiles > 0) {
          const message = 'Failed to upload file; the format is not supported.';
          this.commonService.showToast(0, message);
        }
        const keys = Object.keys(observableList);
        if(keys.length ===0){
          this.commonService.hideLoading();
          this.resetValue();
          return;
        }
        const result = forkJoin(observableList).pipe(
          catchError(error => of(error))
        )
        result.subscribe((response: any) => {
          this.commonService.hideLoading();
          if (response) {
            this.imageOutput.emit(response);
          }
          this.resetValue();
        }, ()=>{
          this.resetValue();
          this.commonService.hideLoading();
        })

      } else {
        if (!this.isValidImageFile(currentFile[0].name)) {
          const message = 'Failed to upload file; the format is not supported.';
          this.commonService.showToast(0, message);
          this.commonService.hideLoading();
          this.resetValue();
          return;
        }

        if (!this.checkFileSize(currentFile[0])) {
          let message = 'For an accurate A.I. analysis please upload image which are not smaller than 200 KB and do not exceed 5 MB';
          if(this.currentFileNumber === 9) {
            message = 'Please upload image which are not smaller than 200 KB and do not exceed 5 MB';
          }
          if(this.currentPageName !== 'photos'){
            if(currentFile[0].type === 'application/pdf'){
              message = `pdf is not of the correct size.  Please provide valid file size  (Max : ${Math.round(pdfSizeLimit/(1024*1024))} MB)`;
            }else{
              message = `Image is not of the correct size.  Please provide valid file size  (Max : ${Math.round(photosSizeLimit/(1024*1024))} MB)`;
            }
          }
          this.commonService.showToast(0, message);
          this.commonService.hideLoading();
          this.resetValue();
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(currentFile[0]);
        reader.onload = (_event: any): void => {
          this.commonService.hideLoading();
          this.resetValue();
          this.imageOutput.emit({ image: reader.result, fileName: currentFile[0].name, file: currentFile[0] });
        }

        reader.onerror = (_event:any):void =>{
          this.resetValue();
          this.commonService.hideLoading();
        }
      }

    } else {
      return;
    }
  }


}
